import React, { Component, useEffect, useState } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import app from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";
import { Link, useLocation } from 'react-router-dom'

const ProjectDetails = (props:any) => {
  const auth = getAuth(app);
  const db = getDatabase();
  let location = useLocation();
  console.log(location.state);
  const pid = location.state
  const [name, setname] = useState<any | null>(null);
  const [desc, setDesc] = useState<any | null>(null);
  const [fundReq, setFundReq] = useState<any | null>(null);
  const [date, setDate] =useState<any | null>(null);
  const [amount, setAmount] = useState<any | null>(null);

  useEffect(() => {

    onValue(ref(db, 'projects/' + pid), (snapshot) => {
      const name = (snapshot.val() && snapshot.val().name);
      const desc = (snapshot.val() && snapshot.val().description);
      const funds = (snapshot.val() && snapshot.val().fundReq);
      const date = (snapshot.val() && snapshot.val().projectStartDate);
      const amount = (snapshot.val() && snapshot.val().amount);
         setname(name) 
         setDesc(desc)
         setFundReq(funds)
         setAmount(amount)
         setDate(date)
    }, {
      onlyOnce: true
    });})
  return (
      <div>
         <HeaderMain/>
        <section >
        <div className="contacts-9 py-lg-5 py-md-4" style = {{backgroundColor:"white", padding:50}}>
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Project Details</h3>
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
          <div>
                {name}                      
                <p className="mt-3" >
                  {desc}
                </p>
                <h5 style={{color:"blue"}}> Funds Required: {fundReq}</h5>
                <h5 style={{color:"blue"}}> Funds Gather: {amount} </h5>
                <h5 style={{color:"blue"}}> Date Started: {date}</h5> 
                </div>
          </div>

      </div>
     
    </div>
  </div>
</div>

         </div>
        </section>

               {/* /bottom-grids*/}
<section className="w3l-bottom-grids-6 py-5" style = {{marginTop:30, textAlign:"center", alignItems:"center", justifyItems:"center", padding:50}}>
  <div className="container py-lg-5 py-md-4 py-2">
    <div className="grids-area-hny main-cont-wthree-fea row" >
      <div className="col-lg-6 col-md-6 grids-feature">
        <div className="area-box">
          <img src="assets/images/aa.png"    style ={{width:100,height:100}}/>
          <h4><a href="#feature" className="title-head"> Donations</a></h4>
          <a href="#donate" className="btn btn-text">200$</a>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 grids-feature mt-md-0 mt-5">
        <div className="area-box">
          <img src="assets/images/abc.png"  style ={{width:100,height:100}}/>
          <h4><a href="#feature" className="title-head"> Contributors</a></h4>
          <a href="contact.html" className="btn btn-text">100 </a>
        </div>
      </div>
     
    </div>
  </div>
</section>

<div className="middle py-5" id="facts">
  <div className="container pt-lg-3">
    <div className="welcome-left text-center py-md-5 py-3">
      <h3 className="title-big">Over 93% of all Donations go directly to Projects.</h3>
      <h4>Thank you for your continued Support </h4>
      <Link to={`/donation`}  state={{id:pid} }
      className="btn btn-primary btn-style mt-3">
        Make Donations   
          </Link>    
          </div>
  </div>
</div>

        <FooterMain/>
      </div>
    )
  
}
export default ProjectDetails;
