import React from 'react';
import Dashboard from './Dashboard';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

function Main() {
  return (
    <div className="wrapper">
       <Header/>
       <Menu/>
       <Dashboard/>
       <Footer/>
    </div>
  );
}
export default Main;
