import React, { Component } from 'react'
import {  Link } from "react-router-dom";

export default class Menu extends Component {
  render() {
    return (
      <div> 
  <aside className="main-sidebar sidebar-dark-primary elevation-4">
  <Link to="/" className="brand-link">
    <img src="dist/img/LOGO.JPG" alt="Logo" className="brand-image img-circle elevation-3" style={{opacity: '0.8'}} />
    <span className="brand-text font-weight-light">Zuela</span>
  </Link>
  <div className="sidebar">
    <div className="user-panel mt-3 pb-3 mb-3  ">
      <div className="info" style ={{color:"White"}}>
        Welcome Admin !!!
      </div>
    </div>
   
    <nav >
      <ul className="nav nav-pills nav-sidebar flex-column" role="menu" data-accordion="false">
          <Link to="/admin" className="nav-link">
            <i className="nav-icon fas fa-tachometer-alt" /> 
            <p>
               &nbsp; Dashboard
            </p>
          </Link>

          <li className="nav-item">
          <Link to="/users" className="nav-link">
          <i className="fas fa-users"></i>
           <p>
           &nbsp; Users
            </p>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/organization" className="nav-link">
          <i className="fa fa-building"></i>
             <p>
             &nbsp; Organization
            </p>
          </Link>
        </li>
    
        <li className="nav-item">
          <Link to="/request" className="nav-link">
          <i className="fas fa-check-square"></i>
            <p>
            &nbsp; Approval request 
            </p>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/posts" className="nav-link">
          <i className="fas fa-chart-line"></i>
           <p>
            &nbsp; Posts/Issues
            </p>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/report" className="nav-link">
          <i className="fas fa-flag"></i>
            <p>
            &nbsp;  Report Issues
            </p>
          </Link>
        </li>
      </ul>
    </nav>
  </div>
</aside>

      </div>
    )
  }
}
