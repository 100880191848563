import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class BodyMain extends Component {
  render() {
    return (
        <div>
  {/* home page block1 */}
    <div className="container">
      <div className="row">
        <div className="col-lg-2 col-md-6 col-sm-12">
          <div className="box-wrap ">
            <h4>
            <Link  to="/issues"  className="title-head">
            <img src="assets/images/1.jpg" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Corruption
            </Link>     
              </h4>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12 mt-md-0 mt-sm-4 mt-3">
          <div className="box-wrap">
          <h4>
                <Link  to="/issues"  className="title-head">
                <img src="assets/images/2.png" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Election</Link></h4>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12 mt-lg-0 mt-sm-4 mt-3">
          <div className="box-wrap">
          <h4>
                <Link  to="/issues"  className="title-head">
                <img src="assets/images/3.png" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Human Rights</Link></h4>
          </div>
        </div>

        <div className="col-lg-2 col-md-6 col-sm-12 mt-lg-0 mt-sm-4 mt-3">
          <div className="box-wrap">
          <h4>
                <Link  to="/issues"  className="title-head">
                <img src="assets/images/4.png" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Politics</Link></h4>
                        </div>
        </div> 
        <div className="col-lg-2 col-md-6 col-sm-12 mt-lg-0 mt-sm-4 mt-3">
          <div className="box-wrap">
          <h4>
                <Link  to="/issues"  className="title-head">
                <img src="assets/images/5.png" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Conflict</Link></h4>
                        </div>
        </div>
         <div className="col-lg-2 col-md-6 col-sm-12 mt-lg-0 mt-sm-4 mt-3">
          <div className="box-wrap">
          <h4>
                <Link  to="/issues"  className="title-head">
                <img src="assets/images/6.png" className='brand-image img-circle elevation-1' style={{width:100, height:100, alignItems:"center"}} />
              Gender Rights</Link></h4>          </div>
        </div>

      </div>
    </div>
  {/* //home page block1 */}
  
 
  {/* stats */}
  <div className="w3_stats py-5" id="stats">
    <div className="container py-lg-5 py-md-4 py-2">
    <h5 className="title-big text-center">Worldwide Reports</h5>

    <h3 className="title-big text-center">1500</h3>
       
    </div>
  </div>
  <div className="w3l-bg py-5">
  <div className="container py-lg-5 py-md-4">
    <div className="welcome-left text-center py-lg-4">
      <span className="fa fa-heart-o" />
      <h3 className="title-big">Promotion Video.</h3>
      <div></div>
      <video width="60%" height="40%" src="assets/images/zuela.mp4" controls/>
     
    </div>
  </div>
</div>

  <section className="w3l-clients py-5" id="clients">
    <div className="call-w3 py-lg-5 py-md-4">
      <div className="container">
        <h3 className="title-big text-center">Our Partners</h3>
        <div className="company-logos text-center mt-5">
          <div className="row logos">
          <div className="col-lg-4 col-md-3 col-4">
              <img src="assets/images/FOA.png"  className="img-fluid" />
            </div>
           
            <div className="col-lg-4 col-md-3 col-4">
              <img src="assets/images/Radio-Angola_Website_FoA.png"  className="img-fluid"  />
            </div>

            <div className="col-lg-4 col-md-3 col-4">
              <img src="assets/images/ned.jpeg"  className="img-fluid" />
            </div>
            
            
        
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    )
  }
}
