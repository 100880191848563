import React, { Component } from 'react'
import FooterMain from './FooterMain'
import HeaderMain from './HeaderMain'
import { Link } from 'react-router-dom'

export default class PivacyPolicy extends Component {
  render() {
    return (
        <div>
            <HeaderMain/>
  <section className="w3l-aboutblock1 py-5" id="about">
  <div className="container py-lg-5 py-md-3">
    <div className="row">
      <div className="col-lg-12">
        <h3 className="title-big">Pivacy Policy</h3>
        <p className="mt-3" style={{color:"#000"}}>This Privacy Policy outlines how we use, share, and protect information collected through your use 
        <a href="https://zuela.org/">Zuela.org</a>
 and its related online services (“Site”). This Privacy Policy also notifies you about the
choices you have with regard to how we collect and use your information. This Privacy Policy is
applicable to all users and visitors of the Site, as well as those who access the Site through any other
means.</p>
       <p className="mt-3" style={{color:"#000"}}>By using the Site, you agree that the purpose of the Site is to function as a political engagement
social networking platform that is aimed at promoting good governance by providing a medium
where individuals with political interests can share, engage and interact with each other or political
organizations and leaders on issues they are passionate about. Through your use of the Site you will
be able to communicate with other individuals or organizations and post content, videos, comments,
and other material. This content comprises the “User Content.” By using the Site, you are posting
the content to the Site and the Site will store a copy of your content. </p>
<h4>
I. INFORMATION COLLECTED
</h4>
       <p className="mt-3" style={{color:"#000"}}> The Site collects various types of information through your use of the site. Each type is outlined
below.
Information that You Provide Directly to the Site:
<ol>
  <li>1.  This includes your username, password, email address, contact information, and any other
information provided when you register for an account on the Site.</li>
  <li>2. Any display names or profile information that you provide to be displayed on your account.</li>
  <li>3. All User Content that you post to the Site including, but not limited to, videos, comments,photos, and other audio, video, or written materials.</li>
  <li>4.  Any communications between you and the Site such as for technical support, security
notices, or notices of changes to the Site. You cannot opt-out of Site-related emails.</li>

</ol> <h5> Analytics Information</h5>
 From time to time, the Site may use third-party analytics tools to collect data about trends in
traffic and usage of the Site. This information will be aggregated and will not be traceable
back to you individually.
<h5> Usage Information</h5>
The Site may collect information about your activity on the Site including how you use the
Site, how you interact with others on the Site, and data and statistics about your use of the
Site. This information will be aggregated and used to help improve the App, and will not be
traceable back to you individually.
<h5>Content Information</h5>
The Site collects information about the content you provide including any metadata
associated with the content and when and the number of times your content is viewed
by others.
<h5> Device Information</h5>
When you access the Site, we may gather device-specific information such as the type of
device being used, the operating system and version, the browser being used, the
language, the wireless network or mobile network information, phone number, or other
unique device identifiers.
<h5>Location Information</h5>
We may collect information about your location including city, state, and country when
you access the Site with your consent through method like GPS, cellular towers, Wi-Fi
access points, and other wireless network information.
<h5>Cookies and Other Technologies</h5>
We may use cookies or other forms of electronic technologies to collect information
about your activity, browser, or device. This information is used in the aggregate and
does not identify you specifically. You may set your browser to reject cookies if you
would prefer they not be used.
<h5>Log File Information</h5>
Log file information is data that is generated and retained each time you use the App.
This includes details like when you log in, your IP address, what device you are using,
how you use the Site, pages and profiles you view, and crash reports.
</p>
<h4>
II. HOW YOUR INFORMATION IS USED
</h4>
<p className="mt-3" style={{color:"#000"}}> All information collected through your use of the Site is to improve the Site and its service. We may
use this information to: develop and improve our product; fix security issues; remove bugs; monitor
usage; identify trends; personalize services for your account; improve our advertising content; verify
your identity; and enforce our usage policies. While this list is not exhaustive, it is representative.
Your information will only be used for the purposes of improving the Site and will not be sold or
leased.</p>
<h4>
III. HOW YOUR INFORMATION IS SHARED
</h4>
<p className="mt-3" style={{color:"#000"}}> 
We will never sell or lease your information to third parties. However, there are some instances
where we share your information. These include:
<ol>
    <li>1. Sharing your public profile information and User Content with other users of the Site,
such as with potential candidates.</li>
<li>2. Sharing any information you have specifically authorized us to share through in-Site
prompts.</li>
<li>3. Sharing content you post or upload to the extent of your User Content permissions. For
example, sharing your information with a potential candidate as directed.
</li>
</ol>
There are also instances when we may share information gathered from you in aggregate form with
other data, in which case it will not be identifiable to you. Situations where this information may be
shared include:
<ol>
<li>1. With third party service providers we engage to help analyze our performance and improve
our services.</li>
<li>2. With potential advertisers to demonstrate our demographics.
We may also be required to share your information for legal reasons if the disclosure is necessary to:
</li>
<li>3. Comply with the applicable law, a government request, or legal proceedings.
</li>
<li>4. Investigating or enforcing any violations of the App usage policies.
</li>
<li>5. Protecting our rights and those of other users.</li></ol>
Additionally, some features of this Site are made possible by the use of third party integrations, and
your information may be shared with these third parties for the purposes of providing the services
available on the Site.
</p>
<h4>
IV. HOW YOUR INFORMATION IS STORED
</h4>
<p className="mt-3" style={{color:"#000"}}> 
Your information will be stored in the United States or in any other country where the Site
maintains servers. We use all commercially reasonable methods to secure your information and take
all reasonable steps to verify your identity before providing access to your account. However, there
is always a potential risk of inadvertent disclosure when transmitting information over the Internet
and you agree to hold the Site harmless for any such disclosures or interference.
We will have access to your available content for as long as you are an active user of the Site. Should
you deactivate your account, your content will be removed within a reasonable time. You may also
delete or edit content you upload at any time.
</p>
<p className="mt-3" style={{color:"#000"}}> 
Additionally, the Site may in its discretion purge content after a reasonable period of time. You
should routinely save any content from the Site for your records. It is your responsibility to back-up
this information and the Site assumes no responsibility for storing copies of your content. However,
the Site may, in its discretion, retain content or information for the purpose of improving the Site.
</p>
<h4>
V. OPTING OUT
</h4>
<p className="mt-3" style={{color:"#000"}}>You may limit our collection of information about your account in the following ways:
<ol>
<li>
 1. Disabling cookies on your browser
 </li>
<li>2.  Denying Site permissions (however, this will result in decreased functionality of the
App)
</li>
<li>3.  Unsubscribing from e-mail communication
</li>
<li>4.  Updating your profile privacy settings</li></ol> </p>
<h4>
VI. CHILDREN’S PRIVACY
</h4>
<p className="mt-3" style={{color:"#000"}}>This Site is not intended for or directed at anyone under the age of thirteen (13) and this Site does
not knowingly collect personal information from anyone under the age of thirteen (13). All users of
this site expressly represent that they are at least eighteen (18) years of age and have the ability to
enter into a binding agreement. </p>

<h4>
VII. REVISIONS
</h4>
<p className="mt-3" style={{color:"#000"}}>This Privacy Policy may be revised from time to time and we will make reasonable attempts to
notify you of such changes. However, you should review this policy periodically. The effective date
of the policy will always be listed at the top of the Privacy Policy. Your continued use of the Site
indicates your consent to any such revisions.</p>
<h4>
VIII. CONTACT US
</h4>
<p className="mt-3" style={{color:"#000"}}>If you have any questions about this Privacy Policy please contact us by emailing
info@friendsofangola.org </p>

      </div>
    
    </div>
  </div>
</section>

            <FooterMain/>
        </div>
    )
  }
}
