import React, { Component , useEffect, useState} from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import {  useParams} from 'react-router-dom' 
import app from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, update, child, set} from "firebase/database";
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";


interface Props {
}
declare const window: any;

const Donations: React.FC<Props> = ({}) => {

  const auth = getAuth(app);
  const db = getDatabase();
  const [amt, setAmt] =  useState<any | null>(null);
  const [showGateway, setShowGateway] = useState(false);

  const [name, setname] = useState<any | null>(null);
  const [desc, setDesc] = useState<any | null>(null);
  const [fundReq, setFundReq] = useState<any | null>(null);
  const [date, setDate] =useState<any | null>(null);
  const [amount, setAmount] = useState<any | null>(null);
  const params = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log(params); 
  const pid = params.id;
  console.log(pid);


  function onMessage(e:any) {
    let data = e.nativeEvent.data;
    setShowGateway(false);
    console.log(data);
   
    let payment = JSON.parse(data);
    if (payment.status === 'COMPLETED') {
      update(ref(db, 'projects/' + pid), {
        name :name,
        description : desc,
        fundReq : fundReq,
        projectStartDate :date,
        amount : parseInt(amt)+parseInt(amount)
     })
      alert('PAYMENT MADE SUCCESSFULLY!');
    } else {
      update(ref(db, 'projects/' + pid), {
        name :name,
        description : desc,
        fundReq : fundReq,
        projectStartDate :date,
        amount : parseInt(amount)
     })
      alert('PAYMENT FAILED. PLEASE TRY AGAIN.');
    }}
    function _createOrder(data:any, actions:any) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: "1",
            },
          },
        ],
      });
    }
    async function _onApprove(data:any, actions:any) {
      let order = await actions.order.capture();
      console.log(order);
       return order;
    }
    function _onError(err:any) {
      console.log(err);
      }
  useEffect(() => {

  onValue(ref(db, 'projects/' + pid), (snapshot) => {
    const name = (snapshot.val() && snapshot.val().name);
    const desc = (snapshot.val() && snapshot.val().description);
    const funds = (snapshot.val() && snapshot.val().fundReq);
    const date = (snapshot.val() && snapshot.val().projectStartDate);
    const amount = (snapshot.val() && snapshot.val().amount);
    setname(name)
     setDate(date)
      setDesc(desc)
       setFundReq(funds)
     setAmount(amount)
  }, {
    onlyOnce: true
  });})
  
  return (
      <div>
           <HeaderMain/>
<div>
<section className="w3l-contact-7 py-5" id="contact">
<div className="contacts-9 py-lg-5 py-md-4">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Make Donations in this project</h3>
        <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="text-right">

          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
            <input type="number" name="amount" id="amount" placeholder="Enter Amount" 
            required style={{color:"#000"}} onChange={e => setAmt(e.target.value)} />
                <div onClick={e => setShowGateway(true)} className="btn btn-primary btn-style mt-3">Confirm and Pay</div>

      {showGateway ?(
        <PayPalScriptProvider options={{ "client-id": "AYKFuFUE1NC2_VNeBKsBoJu8h_SnCRHauHDrJ9XTd7NziOV0mzTwO6eCoCUDxqQsvSfwA0j-2RhoFkqn" }}>
 
        <PayPalButtons
        createOrder={(data:any, actions:any) => _createOrder(data, actions)}
        onApprove={(data:any, actions:any) => _onApprove(data, actions)}
        onCancel={() => _onError("Canceled")}
        onError={(err:any) => _onError(err)}
      />   </PayPalScriptProvider>
     ):null }
          </div>

</form>        
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
      </div>
    )
  }

  export default  Donations;  




























