import React, { Component , useEffect, useState} from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link } from 'react-router-dom'
import app from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";


interface Props {
}

const Project: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db= getDatabase(app);
  const [lstprj, setPrj] =  useState<any | null>(null);
  const user =  auth.currentUser?.uid;
  useEffect(() => {
  
    let isMounted = true; 
    onValue(ref(db, 'projects/'), (snapshot) => {
      let li: {key:any, name: string, desc:string,amount:number, fundreq:number, prjStartdate:any, }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        name:child.val().name,
        desc : child.val().description,
        amount: child.val().amount,
        fundreq: child.val().fundReq,
        prjStartdate:  child.val().projectStartDate,
        })
      console.log(li)
      setPrj(li);

    })});

  return() => {isMounted = false};
}, []);

    return (
      <div>
        <HeaderMain/>
        <section >
         <div className="container">
          <div>
          {user?
          (
            <>
        <Link to={`/createproj`}  className ="btn btn-primary btn-style mt-3 "
                style={{borderWidth:1, borderRadius:5, borderColor:"#fff"}} >
                Create Project</Link>
            </>):null
          }
          </div>
         
 
          <div className="row">
          {lstprj && lstprj.map((item:any) => (

        <div className="col-lg-4 col-md-6 col-sm-12" key={item.key}>
          <div className="box-wrap " style={{marginBottom:15, marginTop:15}}>
            <h4>

                <Link to="#feature" className="title-head" >
                             {item.name}
                <h4> {item.desc} </h4> 
                <h5 style={{color:"blue"}}> Funds Required: {item.fundreq} </h5>
                <h5 style={{color:"blue"}}> Funds Gather: {item.amount} </h5>
                <h5 style={{color:"blue"}}> Date Started: {item.prjStartdate} </h5> 
                 
                <Link to={`/projectdetails`} state={item.key}
                style={{borderWidth:1, borderRadius:5, borderColor:"#fff"}} >
                 See Details </Link>
                </Link></h4>
          </div>
        </div>
        ))}
          </div>
          
       {/* /bottom-grids*/}
<section className="w3l-bottom-grids-6 py-5">
  <div className="container py-lg-5 py-md-4 py-2">
    <div className="grids-area-hny main-cont-wthree-fea row" style = {{marginLeft : 30, textAlign:"center", alignItems:"center", justifyItems:"center", padding:50}}>
      <div className="col-lg-6 col-md-6 grids-feature">
        <div className="area-box">
          <img src="assets/images/aa.png"    style ={{width:100,height:100}}/>
          <h4><Link to="#feature" className="title-head">Total Donations</Link></h4>
          <Link to="#donate" className="btn btn-text">200$</Link>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 grids-feature mt-md-0 mt-5">
        <div className="area-box">
          <img src="assets/images/abc.png"  style ={{width:100,height:100}}/>
          <h4><Link to="#feature" className="title-head">Total Contributors</Link></h4>
          <Link to="contact.html" className="btn btn-text">100 </Link>
        </div>
      </div>
     
    </div>
  </div>
</section>

         </div>
        </section>
        <FooterMain/>
      </div>
    )
  }

  export default Project;


