import React, { Component , useEffect, useState} from 'react'
import app from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
interface Props {
}

const Dashboard: React.FC<Props> = ({}) => {
 

  
  const data = [
    { name: 'Corruption', value: 2},
    { name: 'Election', value: 1 },
    { name: 'Human Rights', value: 5 },
    { name: 'Politics', value: 1 },
    { name: 'Conflict', value: 2 },
    { name: 'Gender Rights', value: 5 },
    { name: 'Natural Disaster', value: 1 },

  ];
  const data1 = [
    { name: 'Male', value: 2},
    { name: 'Female', value: 2 },
    { name: 'Unknown', value: 1 },
   

  ];
  const data2 = [
    { date: '2023-01-01', posts: 3 },
    { date: '2023-01-02', posts: 4 },
    { date: '2023-01-03', posts: 2 },
    { date: '2023-01-04', posts: 5 },
    { date: '2023-01-05', posts: 2 },
    { date: '2023-01-06', posts: 3 },
    { date: '2023-01-07', posts: 1 },
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel:any = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }:any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const auth = getAuth(app);
  const db= getDatabase(app);
  const [user, setUser] =  useState<any | null>(null);
  const [org, setOrg] =  useState<any | null>(null);
  const [project, setPrj] =  useState<any | null>(null);
  const [post, setPost] =  useState<any | null>(null);

  const [lstuser, setUsers] =  useState<any | null>(null);
  const [lstpost, setPosts] =  useState<any | null>(null);
  const [lstprj, setPrjs] =  useState<any | null>(null);
  useEffect(() => {
  
    let isMounted = true; 
    onValue(ref(db, 'users/'), (snapshot) => {
      let li: {key:any, name: string, email:string,dob:string, phoneno:number, gender:string, }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        name:child.val().name,
        email : child.val().email,
        dob: child.val().dateofBirth,
        phoneno: child.val().phoneno,
        gender:  child.val().gender,
        })
      let lii = li.filter((item)=> item.dob !== undefined )
      setUser(lii.length);
     let liii = li.filter((item)=> item.dob == undefined )
      setOrg(liii.length);
      var counts = li.reduce((p:any, c:any) => {
        var name = c.gender
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});
     // setPosts(data)
      console.log( Object.getOwnPropertyNames(counts).
      map(function(e) {return [e, counts[e]];}))

    })});
    onValue(ref(db, 'posts/'), (snapshot) => {
      let li: {key:any, title: string, category :string, address:string, likes:number, tag:string , username:string }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        title:child.val().title,
        category : child.val().category,
        address: child.val().location,
        likes: child.val().like,
        tag:  child.val().tag,
        username: child.val().username
        })
      setPost(li.length);
      console.log(li)
      var counts = li.reduce((p:any, c:any) => {
        var name = c.category
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});
     // setPosts(data)
      console.log( Object.getOwnPropertyNames(counts).
      map(function(e) {return [e, counts[e]];}))
    })});
    onValue(ref(db, 'projects/'), (snapshot) => {
      let li: {key:any, name: string, desc :string }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        name:child.val().name,
        desc : child.val().desc,
    
        })
      console.log(li)
      setPrj(li.length);

    })});


  return() => {isMounted = false};
}, []);

    return (
      <div>
  <div className="content-wrapper">
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Dashboard</h1>
        </div>
        
      </div>
    </div>
  </div>
  <section className="content">
    <div className="container-fluid">
      <div className="row">
      <div className="col-lg-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{user}</h3>
              <p style= {{color:"white"}}>Add Users</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add" />
            </div>
            <div className="small-box-footer"> Total number of users on zuela app</div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{org}</h3>
              <p style={{color: "#fff"}}>Organization</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-stalker" />
            </div>
            <div className="small-box-footer"> Total number of organizations on zuela app</div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{post}</h3>
              <p style= {{color:"white"}}>Posts/Issues</p>
            </div>
            <div className="icon">
              <i className="ion ion-chatbubbles" />
            </div>
            <div className="small-box-footer"> Total number of posts on zuela app</div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="small-box bg-success">
            <div className="inner">
              <h3> {project}</h3>
              <p style= {{color:"white"}}>Projects</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars" />
            </div>
            <div className="small-box-footer"> Total number of projects on zuela app</div>
          </div>
        </div>
        
      
      </div>
      <div className="row">
        <section className="col-lg-7 connectedSortable">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                 Posts by Category 
              </h3>
              <div className="card-tools">
                <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn bg-danger btn-sm" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="tab-content p-0">
              <PieChart width={400} height={400}>
      <Pie
        data={data}
        cx={200}
        cy={200}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
              </div>
            </div>
          </div>
          
          <div className="card bg-gradient-info">
            <div className="card-header border-0">
              <h3 className="card-title">
                <i className="fas fa-th mr-1" />
                	Daily post on Issues               </h3>
              <div className="card-tools">
                <button type="button" className="btn bg-info btn-sm" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn bg-info btn-sm" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div >
        <LineChart width={500} height={300} data={data2}>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="2 2" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="posts" stroke="#fff" />
      </LineChart>
            </div>
          </div>
          
        </section>
        <section className="col-lg-5 connectedSortable">

        
          <div className="card bg-gradient-primary">
            <div className="card-header border-0">
              <h3 className="card-title">
                <i className="fas fa-map-marker-alt mr-1" />
                Country wise issues               </h3>
              <div className="card-tools">
                <button type="button" className="btn btn-primary btn-sm daterange" title="Date range">
                  <i className="far fa-calendar-alt" />
                </button>
                <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse" title="Collapse">
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div id="world-map"/>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2895687731!2d-74.26055986835598!3d40.697668402590374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sin!4v1562582305883!5m2!1sen!2sin" frameBorder={0} style={{border: 0}} allowFullScreen />

            </div>
    
          </div>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                User Gender 
              </h3>
              <div className="card-tools">
                <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn bg-danger btn-sm" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="tab-content p-0">
              <PieChart width={400} height={400}>
      <Pie
        data={data1}
        cx={200}
        cy={200}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {data1.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</div>

      </div>
    )
  }

export default  Dashboard; 