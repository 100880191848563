import React, { Component, useState } from 'react'
import HeaderMain  from './HeaderMain'
import FooterMain from './FooterMain'
import { Link, } from 'react-router-dom'
import app from '../FundRaisingProjects/firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

interface Props {
}
const Login: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const [email, setEmail] = useState<any | null>(null);
  const [password, setPwd] = useState<any | null>(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (email === '' || password === '') {
      alert('All fields are required')
      return;
    }
    console.log(email, password)
    if(email === 'admin123@gmail.com')
    {
      try {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          const name= user.displayName;
          const uid = user.email;
          console.log(uid);
          navigate('/admin', { replace: true });
  
        })
        .catch(function(error){
          // Handle Errors here.
          var errorCode = error.code;
          var errorMsg = error.message;
          if (errorCode ==='auth/wrong-password'){
            alert('Wrong password.');
           } 
           else if(errorCode ==='auth/user-not-found'){
            alert('Email does not exist');
           }
           else {
            alert(errorMsg);
           }});
          } 
      catch (error) {
        alert(error)
      }
    }
else{
    try {
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const name= user.displayName;
        const uid = user.email;
        console.log(uid);
        navigate('/issues', { replace: true });

      })
      .catch(function(error){
        // Handle Errors here.
        var errorCode = error.code;
        var errorMsg = error.message;
        if (errorCode ==='auth/wrong-password'){
          alert('Wrong password.');
         } 
         else if(errorCode ==='auth/user-not-found'){
          alert('Email does not exist');
         }
         else {
          alert(errorMsg);
         }});
        } 
    catch (error) {
      alert(error)
    }}
  };
  
    return (
      
      <div>
          <div>
       <header id="site-header" className="fixed-top">
  <div className="container">
  
 <nav className="navbar navbar-expand-lg stroke">
 <h1><Link className="navbar-brand mr-lg-5" to="/">
     <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
   </Link></h1>

 <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon fa icon-expand fa-bars" />
   <span className="navbar-toggler-icon fa icon-close fa-times " >  
   </span>
 </button>
 <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
   <ul className="navbar-nav w-100">
     <li className="nav-item active">
       <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
     </li>
     <li className="nav-item @@about__active">
       <Link className="nav-link" to="/about">About</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/contact">Contact</Link>
     </li>
     <li className="ml-lg-auto mr-lg-0 m-auto">
       {/*/search-right*/}
       <div className="search-right">
         <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
         {/* search popup */}
         <div id="search" className="pop-overlay">
           <div className="popup">
             <h4 className="mb-3">Search issue</h4>
             <form action="error.html" method="GET" className="search-box">
               <input type="search" placeholder="Enter Keyword" name="search" />
               <button type="submit" className="btn btn-style btn-primary">Search</button>
             </form>
           </div>
           <a className="close" href="#close">×</a>
         </div>
         {/* /search popup */}
       </div>
       {/*//search-right*/}
     </li>
     <li className="nav-item ">
     <Link className="nav-link" to="/login">Login</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link" to="/register">Register</Link>
     </li>
   </ul>
 </div>
 
</nav>
   
  </div>
</header>
 {/* bg */}
 <div className="w3l-bg py-3 ">
    <div className="container py-lg-4 py-md-3">
      <div className="welcome-left text-center py-lg-2 py-md-3" style={{marginTop:80}}>
      <h3 className="title-big"> ZUELA</h3>
    <div className="welcome-left text-center py-lg-2 py-md-3" >
      <h2 className="title-small">Zuela is a civic engagement, social networking
               platform aimed at promoting good governance.
               It provides a medium where individuals can share,
               engage, and interact together or with political
               organizations and leaders on issues they feel
               passionate about.</h2>
                    </div>
   
      </div>
    </div>
  </div>
  {/* banner image bottom shape */}
  <div className="position-relative">
    <div className="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
        </path>
      </svg>
    </div>
  </div>
  {/* //banner image bottom shape */}
</div>

<div>
<section className="w3l-contact-5 py-2" id="contact">
<div className="contacts-9 py-lg-2 py-md-2">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Login</h3>
        <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
        <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="text-right">
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
            <input onChange={(e) => setEmail(e.target.value)} type="email"  placeholder="Email*" required style={{color:"#000"}} />
            <input onChange={(e) => setPwd(e.target.value)} type="password" placeholder="Password*" required style={{color:"#000"}}/>
            <div style={{alignItems:"center", justifyContent:"center" }}>
               <Link to="/forgotpwd"> Forgot Password?</Link></div> 
             <Link to="/register"> Don't have an account? Register</Link>
             <div  onClick={handleSubmit} className="btn btn-primary btn-style mt-3">Login</div>
            <Link to="/issues" className="btn btn-primary btn-style mt-3">Login as Guest User</Link>


          </div>

        </form>
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
     </div>
          )
  }
export default Login;
