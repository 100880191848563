import React, { useCallback, useEffect, useState, useRef } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import "./issues.css"
import { Link, useLocation } from 'react-router-dom'
import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, onValue} from "firebase/database";

interface Props {
}

const Messages: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const [messages, setMessages] = useState<any | null>(null);
  const [msg, setMsgs] =useState<any | null>(null);
  const [rmsg, setRMessages]:any[] = useState([]);
  const [smsg, setSMessages]:any[] = useState([]);
  const [username, setUname]= useState('');
  const [txtmsg, setText] = useState('');
  const [uname, setName]= useState('');
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [date, setDate] = useState(new Date());
  const [pic, setPic] = useState();
  const [userr, setUserr] =useState<any | null>(null);

  let location = useLocation();
  console.log(location.state);
  const uuname = location.state
  function getUnique(arr:any, index:any) {

    const unique = arr
         .map((e:any) => e[index])
  
         // store the keys of the unique objects
         .map((e:any, i:any, final:any) => final.indexOf(e) === i && i)
    
         // eliminate the dead keys & store unique objects
        .filter((e:any) => arr[e]).map((e:any) => arr[e]);      
  
     return unique;
  }
  
    useEffect(() => {
      
      let isMounted = true; 
      onValue(ref(db, 'users/'), (snapshot) => {
        let li :any= []
        snapshot.forEach((child)=>{
         li.push({
          _id: child.key,
          name: child.val().name,
          email: child.val().email,
          pic:child.val().pic,
        })
      // username = reciever
      li.filter((item:any) => item.name == uuname).map(({name,email, deviceid}:any) => setEmail(email) );
      li.filter((item:any) => item.name == uuname).map(({name,email, deviceid,pic}:any) => setPic(pic) );
    //  console.log(email)
      //login user = current user =  sender
      li.filter((item:any) => item.email == auth?.currentUser?.email).map(({name,email, deviceid,pic}:any) => setUname(name) );
      li.filter((item:any) => item.email == auth?.currentUser?.email).map(({name,email, deviceid,pic}:any) => setPic(pic) );

      console.log(uuname)

      //
      const filtered = li.filter((item:any) => item.name == uuname)
      console.log(filtered) 
      setUserr(filtered);

    })});
      onValue(ref(db, 'chats/'), (snapshot) => {
        let msg:any = []
        snapshot.forEach((child)=>{
         msg.push({
          _id: child.val()._id, // reciever name
          createdAt: (child.val().createdAt),
          senderId:child.val().senderId,
          senderName: child.val().senderName, // sender name
          receiverId:child.val().receiverId,
          text: child.val().text,
          user: child.val().user,
          pic:child.val().pic
        })
    
    
       msg = msg.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt));
       console.log(msg)
       const msg1= msg.filter((item:any) => item.senderId ==  auth?.currentUser?.email);
       const msg2= msg.filter((item:any) => item.receiverId ==  auth?.currentUser?.email);

       const mg1= getUnique([...msg1],'receiverId')
       setRMessages(msg2.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt))) 
       const mg2=getUnique([...msg1,...msg2],'senderId')
       setSMessages(msg1.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt)))
       setMessages(mg1)
       console.log(msg1)
       console.log(msg2)
       console.log(mg1)
       console.log(mg2)

       console.log(getUnique(mg1,mg2))

      })});

    return() => {isMounted = false};
  }, []);
  const onSend =() => {
    console.log("msgs")

    const temp:any = date.toLocaleString() 
    const created_At = Date.parse(temp.createdAt); //<--- add this line

    try {
      //_id = reciever name
      const msgs :any={
          _id: uuname,
          createdAt:  new Date().toLocaleString(), 
          text:txtmsg,
          senderId:auth?.currentUser?.email,
          senderName: username, 
          receiverId: email,
          pic:pic}  
      console.log(msgs)
      const reference = ref(db, 'chats/');
      push(reference, msgs
            );
            const message = {
              to: token,
              sound: 'default',
              title:  `${uname}`,
              body:   `${txtmsg}`,
              data: { someData: 'goes here' },
            };
          
             fetch('https://exp.host/--/api/v2/push/send', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(message),
            });
            const notRef = ref(db, 'notification/');
            push(notRef, {
               senderid: auth?.currentUser?.uid,
               title:  `${uname}`,
               body:`${uname} send the message` ,
               created_At: date.toLocaleDateString(),
            });
  } catch (error) {
    
    alert( "Please click on the side user first !!!");
  }
  }
  const setUser=(username:any)=>
  {
    console.log(username)
    setUname(username)
    onValue(ref(db, 'users/'), (snapshot) => {
      let li :any[]= []
      snapshot.forEach((child)=>{
       li.push({
        _id: child.key,
        name: child.val().name,
        email: child.val().email,
        deviceid: child.val().device_id,
        pic:child.val().pic,
      })
    // username = reciever
   const uuemail = li.filter((item:any) => item.name == username);
    //setEmail(uuemail.email)
    li.filter((item) => item.name == username).map(({name,email, deviceid}) => setToken(deviceid) );
    li.filter((item) => item.name == username).map(({name,email, deviceid,pic}) => setPic(pic) );
    li.filter((item) => item.name == username).map(({name,email, deviceid,pic}) => setEmail(email) );

    console.log(email)
    //login user = current user =  sender
    li.filter((item) => item.email == auth?.currentUser?.email).map(({name,email, deviceid}) => setName(name) );
    li.filter((item) => item.email == auth?.currentUser?.email).map(({name,email, deviceid,pic}) => setPic(pic) );

  })});
   onValue(ref(db, 'chats/'), (snapshot) => {
    let msg:any = []
    snapshot.forEach((child)=>{
     msg.push({
      _id: child.val()._id, // reciever name
      createdAt: (child.val().createdAt),
      senderId:child.val().senderId,
      senderName: child.val().senderName, // sender name
      receiverId:child.val().receiverId,
      text: child.val().text,
      user: child.val().user,
      pic:child.val().pic
    })
    msg = msg.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt));
    console.log(msg)
    const msg1= msg.filter((item:any) => item.senderId ==  auth?.currentUser?.email);
    const msg2= msg.filter((item:any) => item.receiverId ==  auth?.currentUser?.email);

    const mg1= msg1.filter((item:any) => item._id ==  username);
    const mg2= msg2.filter((item:any) => item.senderName == username);

    setRMessages(mg2.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt))) 
    setSMessages(mg1.sort((a:any,b:any)=> Date.parse( a.createdAt) - Date.parse(b.createdAt)))
    setMsgs(getUnique(mg1,mg2))
    console.log(msg1)
    console.log(msg2)
    console.log(mg1)
    console.log(mg2)
    console.log(getUnique(mg1,mg2))
   })});
 
  }
  return (
    <div>
    <HeaderMain/>
<div className="gap gray-bg">
<div className="container-fluid">
<div className="row">
  <div className="col-lg-12">
    <div className="row" id="page-contents">
      <div className="col-lg-3">
        <aside className="sidebar static">
          <div className="advertisment-box">
            <h4>advertisment</h4>
            <figure>
              <a href="#" title="Advertisment"><img src="dist/img/ad-widget.jpg"   /></a>
            </figure>
          </div>
          <div className="widget">
              <h4 className="widget-title">Dashboard</h4>
              <ul className="naves">
                <li>
                  <i className="fa fa-clipboard" />
                  <Link to="/issues" >News feed</Link>
                </li>
                
                <li>
                  <i className="fa fa-envelope" />
                  <Link  to="/messages">Messages</Link>
                </li>
                <li>
                  <i className="fa fa-bell" />
                  <Link to="/notifications">Notifications</Link>
                </li>
                <li>
                  <i className="fa fa-file" />
                  <Link to="/project" > Projects</Link>
                </li>
                <li>
                  <i className="fa fa-bar-chart-o" />
                  <Link to="/poll" >Polls</Link>
                </li>
                <li>
                  <i className="fa fa-power-off" />
                  <Link to="/" >Logout</Link>
                </li>
              </ul>
            </div>{/* Shortcuts */}
         
                            
        </aside>
      </div>{/* sidebar */}

      <div className="col-lg-9">
        <div className="central-meta">
          <div className="messages">
            <h5 className="f-title"><i className="ti-bell" />All Messages 
            <span className="more-options"></span></h5>
            <div className="message-box">
              <ul className="peoples">
              { messages && messages.map((it:any) => (
                <li>
                  <figure>
                    <img src={it.pic}  style={{width:50, height:50}}  />
                  </figure>
                  <div className="people-name" onClick={()=>setUser(it._id)}>
                    <span>{it.receiverId == auth?.currentUser?.email ? it.senderName : it._id}</span>
                  </div>
                </li>
              ))}           
              </ul>
              
              
              <div className="peoples-mesg-box">
              { msg && msg.map((item:any) => (
              <>
                <div className="conversation-head">
                  <figure><img src={item.pic}  /></figure>
                  <span>
                    {item.receiverId == auth?.currentUser?.email ? item.senderName : item._id}
                  </span>

                </div>

                <ul className="chatting-area">
<>              
                 { rmsg && rmsg.map((item:any) => (

                   <li className="you">
                   <figure><img src={item.pic}  /></figure>
                   <p><strong>{item.senderName}</strong>
                              <p>{item.text}</p> </p>
                 </li>))}
                 { smsg && smsg.map((item:any) => (

<li className="me">
  <figure><img src={item.pic}  /></figure>
  <p><strong>{item.senderName}</strong>
             <p>{item.text}</p> </p>
</li> ))}
</>
                </ul>
               
              </>
              ))}
            
                <div className="message-text-container">
                <div style={{flexDirection:'row',float:"left"}}>
                  <textarea style={{width:400, height:30}}
                   onChange={(e) => setText(e.target.value)}  aria-multiline />
                  </div>
                  <div onClick={()=>onSend()} className="btn btn-primary btn-style "><i className="fa fa-paper-plane" /></div>
                </div>
            </div>              </div>

          </div>
        </div>	
      </div>
    </div>	
  </div>
</div>
</div>
</div>

    <FooterMain/>
    </div>
    )
  
}
export default Messages;

