import React, { Component, useEffect,useRef, useState } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link, useLocation } from 'react-router-dom'
import "./issues.css"

import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, onValue} from "firebase/database";

interface Props {
}

const Notifications: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const [not, setNot] =useState<any | null>(null);;
  const [shownot, setShowNot] =useState(false);


  const[uid, setUId] = useState('');
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
    let isMounted = true; 
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          setUId(uid);
          
          (async () => {
            onValue(ref(db, 'notification/'), (snapshot) => {
              let li:any = []
              snapshot.forEach((child)=>{
               li.push({
                text: child.val().body,
                time: child.val().created_At,
                senderId: child.val().senderid,
              })
            li= li.filter((item:any) => item.senderId != uid)
            console.log(li);
            setNot(li)
            setShowNot(true)
            })});
          })();
          // ...
        } else {
          setNot([])
          setShowNot(false)
         // setUname(username);
          // User is signed out
          // ...
        }
      });
    
    return() => {isMounted = false};

  }, [uid]);
  return (
    <div>
    <HeaderMain/>
<div className="gap gray-bg">
<div className="container-fluid">
<div className="row">
  <div className="col-lg-12">
    <div className="row" id="page-contents">
      <div className="col-lg-3">
        <aside className="sidebar static">
          <div className="advertisment-box">
            <h4>advertisment</h4>
            <figure>
              <a href="#" title="Advertisment"><img src="dist/img/ad-widget.jpg"   /></a>
            </figure>
          </div>
          <div className="widget">
              <h4 className="widget-title">Dashboard</h4>
              <ul className="naves">
                <li>
                  <i className="fa fa-clipboard" />
                  <Link to="/issues" >News feed</Link>
                </li>
                
                <li>
                  <i className="fa fa-envelope" />
                  <Link  to="/messages">Messages</Link>
                </li>
                <li>
                  <i className="fa fa-bell" />
                  <Link to="/notifications">Notifications</Link>
                </li>
                <li>
                  <i className="fa fa-file" />
                  <Link to="/project" > Projects</Link>
                </li>
                <li>
                  <i className="fa fa-bar-chart-o" />
                  <Link to="/poll" >Polls</Link>
                </li>
                <li>
                  <i className="fa fa-power-off" />
                  <Link to="/" >Logout</Link>
                </li>
              </ul>
            </div>{/* Shortcuts */}
         
                            
        </aside>
      </div>{/* sidebar */}
  <div className="col-lg-9">
<div className="central-meta">
<div className="editing-interest">
  <h5 className="f-title"><i className="ti-bell" />All Notifications </h5>
  <div className="notification-box">

    {
      shownot?(
        <>
        {not && not.map((item:any) => (


<ul key={item.key}>
  
  <li>
    <figure><img src="dist/img/bell.png"   /></figure>
    <div className="notifi-meta">
      <p>{item.text} </p>
      <span>{item.time}</span>
    </div>
    <i className="del fa fa-close" />
  </li>
</ul>
))}
        </>
      ):
       <>
      <h3>
        No Notifications to show
      </h3>
      </>
    }
  
  </div>
</div>
</div>	
</div>{/* centerl meta */}

    </div>	
  </div>
</div>
</div>
</div>

    <FooterMain/>
    </div>
    )
 
  
}
export default Notifications;

