import React, { Component, useEffect, useState } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link, useLocation } from 'react-router-dom'

import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push,set, update, onValue} from "firebase/database";

interface Props {
}

const Takepoll: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const [uidd, setUidd]= useState('')
//  const uid = route.params.Uid;
//  console.log("id"+uid);
  const [polls, setPoll] =useState<any | null>(null);;
  const [loading, setLoading] = useState(true); // Set loading to true on component mount
  let location = useLocation();
  console.log(location.state);
  const pollid = location.state
  const [response, setResponse] = useState('');
  const [statement, setStatement] = useState([]);
  const [showstatement, setShowStatement] = useState(true);
  const [uid, setUId] =  useState<any | null>(null);

  let lii :any= [];

  useEffect(() => {
    let isMounted = true; 
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUId(uid);
        console.log(uid);
         onValue(ref(db, 'users/' + uid), (snapshot) => {
          const username = (snapshot.val() && snapshot.val().name);
          console.log(username)
          // ...
          
        }, {
          onlyOnce: true
        });
        // ...
      } 
    
    });
      onValue(ref(db, 'polls/'+pollid+'/statements/'), (snapshot) => {
      let li:any = []
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        value: child.val().value,
        result: child.val().result,
      })
      setStatement(li);
     // console.log(statement);
    })});

    return() => {isMounted = false};

  }, []);

  function updateStatement(key:any) {
    setShowStatement(false)
    onValue(ref(db, 'polls/'+pollid+'/statements/'+key+'/result/'), (snapshot) => {
     let lii:any = []
      snapshot.forEach((child)=>{
       lii.push({
        response: child.val().response,
        uid: child.val().uid
      })
      console.log(lii);
    })});
    const obj = [...lii, { uid: uid, response: response}]
    console.log(obj);
  update(ref(db, 'polls/'+pollid+'/statements/'+key), {
    result: obj
  })
  .then(() => {
    // Data saved successfully!
    alert("Poll taken successfully!!!")
    window.history.pushState({}, '','/poll')
    window.history.go(0);
  })
  .catch((error:any) => {
    // The write failed...
    alert(error.message)
  });

  };
  return (
      
    <div>
          <HeaderMain/>
    <div>
    <section className="w3l-contact-7 py-5" id="contact">
    <div className="contacts-9 py-lg-5 py-md-4">
    <div className="container">
      <div className="top-map">
        <div className="row map-content-9">
          <div className="col-lg-12" >
            <h3 className="title-big">Take Poll</h3>
            <p className="mb-4 mt-lg-0 mt-2"  style={{color:"black"}}> Rate every statement carefully!!!</p>
             
            {statement && statement.map((item:any) => (
              <div className="box-wrap " style={{display: "flex", flexDirection:'column', margin:10, padding:30, backgroundColor:"#fff"}}>

              {showstatement ? (
                <>
                  <h4>  {item.value}</h4>         
                <select  onChange={(e) => setResponse(e.target.value)} style={{textAlign:"left", padding:15}}>
                  <option value="1" selected>1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
               </select> 
               
               <div onClick={()=>{updateStatement(item.key)}}  className="btn btn-primary btn-style mt-3">Save</div>
      
                </>
              
              ) : <h1> You have taken this poll !!!</h1>}
              </div>
        
    
            ))}            
    
          </div>
         
        </div>
      </div>
    </div>
    </div>
    </section>
    
    </div>
    
          <FooterMain/>
          </div>
        )
}
export default Takepoll;

