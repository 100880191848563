import React, {  useEffect, useState} from 'react'
import Header from '../Main-admin/Header';
import Menu from '../Main-admin/Menu';
import Footer from '../Main-admin/Footer';
import app from '../Main/FundRaisingProjects/firebase';
import { getAuth} from "firebase/auth";
import { getDatabase, ref,update, onValue} from "firebase/database";

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

interface Props {
}

const Users: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db= getDatabase(app);
  const [lstuser, setUser] =  useState<any | null>(null);
  const [open, setOpen] = React.useState(false);
  const [uname, setUname] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [dob, setDob] = useState('')
const [pic, setPic] = useState('')
const [gender, setGen] = useState('')
const [status, setStatus] = useState('Active')
const [address, setAddress] = useState('')
const [uid, setUid] = useState('')
const [password, setPwd] = useState('')
const [isagree, setIsagree] = useState(true)
const [deviceid, setDeviceid ] = useState(true)
  
const handleClickOpen = (id:any) => {
    setOpen(true);
    setUid(id)
    onValue(ref(db, 'users/' + id), (snapshot) => {
      const username = (snapshot.val() && snapshot.val().name);
      const uemail = (snapshot.val() && snapshot.val().email);
      const uphone = (snapshot.val() && snapshot.val().phoneno);
      const udob = (snapshot.val() && snapshot.val().dateofBirth);
      const upic = (snapshot.val() && snapshot.val().pic);
      const ugen = (snapshot.val() && snapshot.val().gender);
      const uaddress = (snapshot.val() && snapshot.val().address);
      const ustatus = (snapshot.val() && snapshot.val().status);
      const upwd = (snapshot.val() && snapshot.val().password);      
      const agree = (snapshot.val() && snapshot.val().isAgree);
      const device = (snapshot.val() && snapshot.val().device_id);
      
      setPwd(upwd)
      setPic(upic)
      setIsagree(agree)
      console.log(username)
      setUname(username)
      setEmail(uemail)
      setPhone(uphone)
      setDob(udob)
      setGen(ugen)
      setStatus(ustatus)
      setAddress(uaddress)
      setDeviceid(device)
    }, {
      onlyOnce: true
    });
  };
  
const handleClose = () => {
    setOpen(false);
  };
function updateUser(uid:any, s:any) {
 
  
    console.log(s);
    if (s === "Active")
    {
      s = "Deactivate"
    }
    else{
      
      s = "Active"
    }
    console.log(status)
    update(ref(db, 'users/' + uid), {
      name: uname,
      email: email,
      phoneno: phone,
      dateofBirth: dob,
      address: address,
      password: password,
      gender: gender,
      pic: pic,
      status: s,
      device_id: deviceid,
      isAgree: isagree
    })
    .then(() => {
    })
    .catch((error) => {
      // The write failed...
    });
  
    
  };
useEffect(() => {
  
    let isMounted = true; 
    onValue(ref(db, 'users/'), (snapshot) => {
      let li: {key:any, name: string, email:string,dob:string, phoneno:number, gender:string, status:string}[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        name:child.val().name,
        email : child.val().email,
        dob: child.val().dateofBirth,
        phoneno: child.val().phoneno,
        gender:  child.val().gender,
        status: child.val().status
        })
      li = li.filter((item)=> item.dob !== undefined )
      console.log(li)
      setUser(li);

    })});

  return() => {isMounted = false};
}, []);


return (
      <div>
        <Header/>
        <Menu/>
       
  <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Users</h1>
            </div>
            
          </div>
        </div>
      </div>
    <section className="content">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Users on Zuela</h3>
            <div className="card-tools">
            </div>
          </div>
          

          <div className="card-body table-responsive p-0">
  
         
  <table className="table table-hover text-nowrap">
    <thead>
      <tr>
        <th>ID</th>
        <th>User</th>
        <th>Email</th>
        <th>Date of Birth</th>
        <th>Contact No</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
{lstuser && lstuser.map((item:any) => (
  <tr key={item.key}>
    <th>{item.key}</th>
  <td>{item.name}</td>
  <td>{item.email}</td>
  <td>{item.dob}</td>
  <td>{item.phoneno}</td>
  {item.status =="Active"?<>
  <td><span className="badge bg-success">{item.status}</span></td>
  </>:
    <td><span className="badge bg-danger">{item.status}</span></td>
  }
  
  <td><span className="badge bg-info" onClick={()=>handleClickOpen(item.key)}>View</span></td>
</tr>
    
    ))} 
 </tbody>
  </table>
</div>
<Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {uname}
    </DialogTitle>
        <DialogContent>
          <DialogContentText>
    <div>
  <img src={pic} alt={uname} style={{width: '100%'}} />
  <div> &nbsp; </div>
   <h6><strong>Name :</strong> {uname}</h6> 
   <div> &nbsp; </div>
   <h6><strong>Email :</strong> {email}</h6> 
   <div> &nbsp; </div>
   <h6><strong>Phone no :</strong> {phone}</h6> 
   <div> &nbsp; </div>
   <h6><strong>Gender :</strong> {gender}</h6> 
   <div> &nbsp;</div>
   <h6><strong>Date of Birth  :</strong> {dob}</h6> 
 
</div>
        
</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>updateUser(uid, status) } color="secondary" >{status=== "Active" ?"Deactivate":"Active"}</Button>
        <Button onClick={()=>handleClose()} color="primary" >Close</Button>
        </DialogActions>
      </Dialog>
        </div></div></div></div></section>

      </div>
      <Footer/>
      </div>
    
     
    )
}

export default Users;
