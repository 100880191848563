import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default class Header extends Component {
  render() {
    return (
      <div>
        

<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  <ul className="navbar-nav">
  <li className="nav-item">
<a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
</li>
    <li className="nav-item d-none d-sm-inline-block">
    <Link to="/" className="nav-link">Home</Link>
    </li>
  </ul>
  <ul >
  
    <li >
    <Link to="/" className="nav-link" data-toggle="dropdown" >
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge"></span>
      </Link>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> 4 new messages
          <span className="float-right text-muted text-sm">3 mins</span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/"className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item dropdown-footer">See All Notifications</Link>
      </div>
    </li>
    <li className="nav-item">
    <Link to="/"className="nav-link" data-widget="fullscreen" role="button">
        <i className="fas fa-expand-arrows-alt" />
    </Link>
    </li>
    <li className="nav-item">
    <Link to="/" className="nav-link"  data-toggle="dropdown"  >
      <i className="far fa-user" />

      </Link>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">Logout
                </span>

      </div>
    </li>
  </ul>
</nav>



      </div>
    )
  }
}
