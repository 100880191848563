import React, { Component, useState, useEffect } from 'react'
import HeaderMain  from './HeaderMain'
import FooterMain from './FooterMain'
import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, onValue, update, child, set} from "firebase/database";
import { getStorage , uploadBytesResumable ,uploadBytes, ref as ref_storage, getDownloadURL} from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import {Link, useNavigate} from 'react-router-dom'

interface Props {

}
const RegisterUser: React.FC<Props> = ({}) => {

  const auth = getAuth(app);
  const db = getDatabase();
  const storage = getStorage(app);
  const navigate = useNavigate();

  
  const [name, setName] = useState<any | null>(null);
  const [email, setEmail] = useState<any | null>(null);
  const [phone, setPhn] = useState<any | null>(null);
  const [address, setAddress] = useState<any | null>(null);
  const [password, setPwd] = useState<any | null>(null);
  const [date, setDate] = useState<any | null>(null);
  const [gender, setGender] = useState<any | null>(null);
  const [file, setFile] = useState<any | null>(null);
  const [logo, setLogo] = useState<any | null>(null);

  const [pickedPath, setpickedPath] =  useState<any | null>(null);
  const [imgPath, setimgPath] =  useState<any | null>(null);
  const [pathname, setPathname] =  useState<any | null>(null);
  const [imgsrc, setimgSrc] =  useState<any | null>(null);
  const [transferred, setTransferred] = useState(0);


  const [percent, setPercent] = useState(0);
  const gen = ["Male", "Female", "Unknown"]

  const RenderList=()=>{
    let tempArray = [];
      let times = gen;
   
           for(var i = 0; i < times.length; i++){
              tempArray.push(<option>{times[i]}</option>)
           }
   
           return tempArray
   }
   
   const upload = async (selectorFiles:any) => {
    if (!selectorFiles) {
    alert("Please upload an image first!");
    }
    if (selectorFiles) {
    setimgPath(selectorFiles[0]);
    console.log(selectorFiles[0]['name']);
    setPathname(selectorFiles[0]['name'])
    console.log( URL.createObjectURL(selectorFiles[0]))
    setpickedPath( URL.createObjectURL(selectorFiles[0]))

    const storageRef = ref_storage(storage, `/images/${selectorFiles[0]['name']}`);
    const metadata = {
      contentType: '*/*',
    };
   try { 
    await uploadBytes (storageRef, selectorFiles[0], metadata).then
    ((snapshot:any) => {
      setTransferred(
      Math.round(snapshot.bytesTransferred /
       snapshot.totalBytes) * 10000);
    })
    // alert('Upload file successfully');
     getDownloadURL(storageRef)
     .then((url) => {
       console.log(url); 
       //setUrl(url);
       setpickedPath(url)
     })
     .catch((error) => {
       switch (error.code) {
         case 'storage/object-not-found':
           // File doesn't exist
           alert("File doesn't exist")
           break;
         case 'storage/unauthorized':
           // User doesn't have permission to access the object
           alert("User doesn't have permission to access the object")
           break;
         case 'storage/canceled':
           alert("User canceled the upload")
           break;  
         case 'storage/unknown':
           // Unknown error occurred, inspect the server response
           alert("Unknown error occurred, inspect the server response")
           break;
       }});
     } catch (error) {
         alert(error)
     } finally {
     };
  }} 
const signUp = async ()=>{
    console.log(name, email, password)
    try {
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential:any) => {
        // Signed in 
        const user = userCredential.user;
        const uid = user.uid
        console.log(user)
        console.log(uid)

        const reference = ref(db, 'users/' + uid);
        set(reference, {
          uid: uid,
          email: user.email,
          name: name,
          phoneno: phone,
          
          address: address,
          password: password,
          gender: gender,
          dateofBirth: date,
          
          pic: pickedPath,
         
        });

        // ...
        alert('User Register Successfully');
        navigate('/issues', { replace: true });

      })
      .catch((error) => {
        alert(error.message);
        // ..
      });
    

    } catch (e) {

    }
  }

    return (
      <div>
    <div>
       <header id="site-header" className="fixed-top">
  <div className="container">
  
 <nav className="navbar navbar-expand-lg stroke">
 <h1><Link className="navbar-brand mr-lg-5" to="/">
     <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
   </Link></h1>

 <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon fa icon-expand fa-bars" />
   <span className="navbar-toggler-icon fa icon-close fa-times " >  
   </span>
 </button>
 <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
   <ul className="navbar-nav w-100">
     <li className="nav-item active">
       <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
     </li>
     <li className="nav-item @@about__active">
       <Link className="nav-link" to="/about">About</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/contact">Contact</Link>
     </li>
     <li className="ml-lg-auto mr-lg-0 m-auto">
       {/*/search-right*/}
       <div className="search-right">
         <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
         {/* search popup */}
         <div id="search" className="pop-overlay">
           <div className="popup">
             <h4 className="mb-3">Search issue</h4>
             <form action="error.html" method="GET" className="search-box">
               <input type="search" placeholder="Enter Keyword" name="search" />
               <button type="submit" className="btn btn-style btn-primary">Search</button>
             </form>
           </div>
           <a className="close" href="#close">×</a>
         </div>
         {/* /search popup */}
       </div>
       {/*//search-right*/}
     </li>
     <li className="nav-item ">
     <Link className="nav-link" to="/login">Login</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link" to="/register">Register</Link>
     </li>
   </ul>
 </div>
 
</nav>
   
  </div>
</header>
 {/* bg */}
 <div className="w3l-bg py-3 ">
    <div className="container py-lg-4 py-md-3">
      <div className="welcome-left text-center py-lg-2 py-md-3" style={{marginTop:80}}>
      <h3 className="title-big"> ZUELA</h3>
    <div className="welcome-left text-center py-lg-2 py-md-3" >
      <h2 className="title-small">Zuela is a civic engagement, social networking
               platform aimed at promoting good governance.
               It provides a medium where individuals can share,
               engage, and interact together or with political
               organizations and leaders on issues they feel
               passionate about.</h2>
                    </div>
   
      </div>
    </div>
  </div>
  {/* banner image bottom shape */}
  <div className="position-relative">
    <div className="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
        </path>
      </svg>
    </div>
  </div>
  {/* //banner image bottom shape */}
</div>

<div>
<section className="w3l-contact-5 py-2" id="contact">
<div className="contacts-9 py-lg-2 py-md-2">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">User Register</h3>
        <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
        <form className="form-grid" action='#'>
          <div style={{display: "flex", flexDirection:'row', color:"black"}}>
            <label htmlFor="myfile">User Image:</label>
          <input  type="file" name="file2" id="my-file2" accept='image/*'  
           onChange={(e) => {
            console.log(e.target.files);
            upload(e.target.files)
          }}
           />
          </div>
          <input onChange={(e) => setName(e.target.value)} type="text"  placeholder="Name*" required style={{color:"#000"}} />
          <input onChange={(e) => setEmail(e.target.value)} type="email"  placeholder="Email*" required style={{color:"#000"}}/>
          <input onChange={(e) => setPhn(e.target.value)} type="text"  placeholder="Phone number*" required style={{color:"#000"}}/>
          <input onChange={(e) => setAddress(e.target.value)} type="text" placeholder="Address" required style={{color:"#000"}}/>
          <input onChange={(e) => setPwd(e.target.value)} type="password" placeholder="Password*" required style={{color:"#000"}}/>
        
         <select  onChange={(e) => setGender(e.target.value)} style={{textAlign:"left", padding:15}}>
            <option defaultValue="none" >Select Gender</option>
              {RenderList()}</select>
         <input onChange={(e) => setDate(e.target.value)} type="date" 
           style={{color:"#000"}}></input>         
         <div  onClick={signUp} className="btn btn-primary btn-style mt-3">Register</div>
        </form>
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
     </div>
         )
  }

export default  RegisterUser ;