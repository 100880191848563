import React, { Component, useEffect, useState } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link, useLocation } from 'react-router-dom'
import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, onValue} from "firebase/database";

interface Props {
}

const Viewpoll: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const [uidd, setUidd]= useState('')
//  const uid = route.params.Uid;
//  console.log("id"+uid);
  const [polls, setPoll] =useState<any | null>(null);;
  const [loading, setLoading] = useState(true); // Set loading to true on component mount
  let location = useLocation();
  console.log(location.state);
  const pollid = location.state

  const uid = auth?.currentUser?.uid;

  const [statement, setStatement] = useState([]);
  const [value, setVal] = useState([]);

  const [count, setCount] = useState(0);
  const [sum, setSum] = useState<any | null>(null);

  useEffect(() => {

      onValue(ref(db, 'polls/'+pollid+'/statements/' ), (snapshot) => {
      let li :any= []
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        value: child.val().value,
        result: child.val().result,
      })
      setVal(li);
      const total = li.length;
      //console.log(li.length);
      let lii:any = [];let i = 0;
      for( i = 0; i< total;i++)
      {
       lii.push(li[i]["result"]);
       setStatement(lii);
       const totalProps = lii.reduce((a:any, obj:any) => a + Object.keys(obj).length, 0);
       setCount(totalProps);
       console.log(lii[1]);
      let output = [];
   for(let j = 0; j< 1;j++){
   console.log(i, j);

    output.push(lii[0][j]["response"])
   } 
   console.log(output);
   const sum = output.reduce((a, b) => parseInt(a) +parseInt(b), 0);
   console.log(sum );

   const per = ((sum/(count*10))*100).toFixed(2)
   //console.log(per);
   setSum( per);



      }
  })});
 
}, [count, sum]);
 
  return (
      
    <div>
          <HeaderMain/>
    <div>
    <section className="w3l-contact-7 py-5" id="contact">
    <div className="contacts-9 py-lg-5 py-md-4">
    <div className="container">
      <div className="top-map">
        <div className="row map-content-9">
          <div className="col-lg-12" >
            <h3 className="title-big">View Poll</h3>
            <p className="mb-4 mt-lg-0 mt-2"  style={{color:"black"}}> Poll Results</p>
            {value && value.map((item:any) => (

            <div className="box-wrap " style={{display: "flex", flexDirection:'column', margin:10, padding:30, backgroundColor:"#fff"}}>
            <h3  style={{color:"#2980b9"}}> {item.value} </h3>
            <div style={{borderRadius:3, backgroundColor:"#ecf0f1", height:30}}>
                       <div style={{width:`${sum}%`, backgroundColor:"#2980b9",height:30}}>
                       <p style={{color:"white",textAlign:"center",padding:2}}> {sum}%</p>
                       </div>
                       </div>
            </div>          
           
            ))}
             <div className="box-wrap " style={{display: "flex", flexDirection:'row', margin:10, padding:30, backgroundColor:"#fff"}}>
             <h3  style={{color:"#2980b9"}}>Responses </h3>
             <p style={{color:"black",fontSize:40, marginLeft:20}}>  {count}  </p>
             </div>
             <div className="box-wrap " style={{display: "flex", margin:15, alignItems:"center", justifyContent:"center"}}>
    
             <Link to="/takepoll">
            <span className="btn btn-primary btn-style mt-3">Participate in Poll</span>
            </Link>   </div>              
          </div>
         
        </div>
      </div>
    </div>
    </div>
    </section>
    
    </div>
    
          <FooterMain/>
          </div>    )
}
export default Viewpoll;
