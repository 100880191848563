import React, { Component } from 'react'
import HeaderMain  from './HeaderMain'
import FooterMain from './FooterMain'

export default class Contact extends Component {
  render() {
    return (
      <div>

            <HeaderMain/>
<div>
  {/* contacts */}
  <section className="w3l-contact-7 py-5" id="contact">
    <div className="contacts-9 py-lg-5 py-md-4">
      <div className="container">
        <div className="top-map">
          <div className="row map-content-9">
            <div className="col-lg-8">
              <h3 className="title-big">Send us a Message</h3>
              <p className="mb-4 mt-lg-0 mt-2"  style={{color:"#000"}}>Your email address will not be published. Required fields are marked *</p>
              <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="text-right">
                <div className="form-grid">
                  <input type="text" name="w3lName" id="w3lName" placeholder="Name*" required />
                  <input type="email" name="w3lSender" id="w3lSender" placeholder="Email*" required />
                  <input type="text" name="w3lPhone" id="w3lPhone" placeholder="Phone number*" required />
                  <input type="text" name="w3lSubject" id="w3lSubject" placeholder="Subject" />
                </div>
                <textarea name="w3lMessage" id="w3lMessage" placeholder="Message" defaultValue={""} />
                <button type="submit" className="btn btn-primary btn-style mt-3">Send Message</button>
              </form>
            </div>
            <div className="col-lg-4 cont-details">
              <address>
                <h5>Contact Address</h5>
                <p  style={{color:"#000"}}><span className="fa fa-map-marker"   style={{color:"#8ec3e6"}}/>2300 18th St NW # 21602 Washington, DC 20009</p>
                <p > <a href="mailto:info@example.com" style={{color:"#000"}}><span className="fa fa-envelope"  style={{color:"#8ec3e6"}} />info@friendsofangola.org</a></p>
                <p style={{color:"#000"}}><span className="fa fa-phone"  style={{color:"#8ec3e6"}} /> +1 202-926-5772</p>
              
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //contacts */}
  <div className="map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198741.64019722922!2d-77.30995730662276!3d38.89338241375145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1685917950319!5m2!1sen!2sin" width="600" height="450" allowFullScreen></iframe>
  </div>
</div>

            <FooterMain/>
      </div>
    )
  }
}
