import React, { Component , useEffect, useState} from 'react'
import Header from '../Main-admin/Header';
import Menu from '../Main-admin/Menu';
import Footer from '../Main-admin/Footer';
import app from '../Main/FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
interface Props {
}
const Report: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db= getDatabase(app);
  const [lstPost, setPost ]=  useState<any | null>(null);
  const [category, setCategory] = useState('')
  const [tags, setTag] = useState('')
  const [text, setText] = useState('')
  const [title, setTitle] = useState('')
  const [username, setUname] = useState('')
  const [pickedPath, setpickedPath] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id:any) => {
    setOpen(true);
    onValue(ref(db, 'posts/' + id), (snapshot) => {
    
      const username = (snapshot.val() && snapshot.val().username);
      setUname(username);
      const category = (snapshot.val() && snapshot.val().category);
      setCategory(category);
      const path = (snapshot.val() && snapshot.val().path);
      setpickedPath(path);
      const tag = (snapshot.val() && snapshot.val().tag);
      setTag(tag);
      const texts = (snapshot.val() && snapshot.val().text);
      setText(texts);
      console.log(text)
      const title = (snapshot.val() && snapshot.val().title);
      setTitle(title);
      
    })
  };
  
const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
  
    let isMounted = true; 
    onValue(ref(db, 'posts/'), (snapshot) => {
      let li: {key:any, title: string, category :string, address:string, likes:number, tag:string , username:string, reportId:string }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        title:child.val().title,
        category : child.val().category,
        address: child.val().location,
        likes: child.val().like,
        tag:  child.val().tag,
        username: child.val().username,
        reportId: child.val().reportId,
        })
        li = li.filter((item)=> item.reportId !== undefined )
        console.log(li)
      setPost(li);

    })});

  return() => {isMounted = false};
}, []);
  
    return (
      <div>
      <Header/>
      <Menu/>
      <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Reports</h1>
            </div>
            
          </div>
        </div>
      </div>
    <section className="content">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Issue Reports on Zuela</h3>
          </div>
          <div className="card-body table-responsive p-0">
  <table className="table table-hover text-nowrap">
    <thead>
      <tr>
        <th>Post Title</th>
        <th>Category</th>
        <th>Tag</th>
        <th>Address</th>
        <th>Likes</th>
        <th>Post by</th>
        <th>Report by</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
    {lstPost && lstPost.map((item:any) => (

      <tr key={item.key}>
        <td>{item.title}</td>
        <td>{item.category}</td>
        <td>{item.tag}</td>
        <td>{item.address}</td>
        <td>{item.likes}</td>
        <td>{item.reportId}</td>
        <td>{item.username}</td>

        <td><span className="badge bg-primary" onClick={()=>handleClickOpen(item.key)}>View</span></td>
      </tr>
    ))}
    </tbody>
  </table>
</div>


<Dialog open={open} onClose={handleClose}>
    <DialogTitle>
          <h1>{title}</h1>
    </DialogTitle>
        <DialogContent>
          <DialogContentText>
    <div>
  <img src={pickedPath} alt="" style={{width: '100%'}} />
   <div> &nbsp; </div>
   <div> &nbsp; </div>
   <h6>{text}</h6> 
   <h6><strong>Category :</strong> {category}</h6> 
   <h6><strong>Tag :</strong> {tags}</h6> 
   <h6><strong>Post By  :</strong> {username}</h6> 
 
</div>
        
</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>handleClose()} color="primary" >Close</Button>
        </DialogActions>
      </Dialog>
        </div></div></div></div></section>

      </div>
      <Footer/>
      </div>   )
  
}
export default Report;