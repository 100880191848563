// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDs8Evg5uGeW2gByF773lDAB1ddROGQ1PY",
  authDomain: "zuela-dcac9.firebaseapp.com",
  databaseURL: "https://zuela-dcac9-default-rtdb.firebaseio.com/",
  projectId: "zuela-dcac9",
  storageBucket: "zuela-dcac9.appspot.com",
  messagingSenderId: "705872199463",
  appId: "1:705872199463:web:a75db779cbfccd552ab302",
  measurementId: "G-F39QM1WBJF"
};
// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app
