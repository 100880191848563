import React, { Component } from 'react'

export default class FooterMain extends Component {
  render() {
    return (
       <div className="w3l-footer-main">
  <div className="w3l-sub-footer-content">
   
    {/* Footers-14 */}
    <footer className="footer-14">
      <div id="footers14-block">
        <div className="container">
          <div className="footers20-content">
            <div className="d-grid grid-col-4 grids-content">
              <div className="column">
                <h4>Our Address</h4>
                <p>2300 18th St NW # 21602 Washington, DC 20009</p>
              </div>
              <div className="column">
                <h4>Call Us</h4>
                <p><a href="##">+1 202-926-5772</a></p>
              </div>
              <div className="column">
                <h4>Mail Us</h4>
                <p><a href="mailto:info@example.com">info@friendsofangola.org</a></p>
                <p><a href="mailto:no.reply@example.com">no.reply@friendsofangola.org</a></p>
              </div>
              <div className="column">
                <h4>Follow Us On</h4>
                <ul>
                  <li><a href="https://www.facebook.com/friendsofangola"><span className="fa fa-facebook" aria-hidden="true" /></a>
                  </li>
                  <li><a href="https://www.youtube.com/channel/UCWgpMNSGaivynxgAoG6-PHA?view_as=subscriber"><span className="fa fa-youtube" aria-hidden="true" /></a>
                  </li>
                  <li><a href="https://twitter.com/friendsofangola"><span className="fa fa-twitter"  /></a>
                  </li>
                  <li><a href="https://www.instagram.com/friendsofangola/"><span className="fa fa-instagram" aria-hidden="true" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footers14-bottom d-flex">
            <div className="copyright">
              <p>
              <strong>Copyright © 2023 <a href="https://zuela.org/">zuela.org</a> </strong>
   | All rights reserved.
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
    {/* Footers-14 */}
  </div>
</div>

    )
  }
}
