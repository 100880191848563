import React, { Component, useState, useRef, useEffect } from 'react'
import FooterMain from '../main-components/FooterMain'
import { Link, } from 'react-router-dom'
import app from '../FundRaisingProjects/firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase,  ref as dbref, push , onValue} from 'firebase/database';
import { getStorage ,  uploadBytes ,uploadString, ref as ref_storage, getDownloadURL} from "firebase/storage";
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Webcam from "react-webcam";
import { useNavigate } from 'react-router-dom';

interface Props {

}

const Posts: React.FC<Props> = ({}) => {
  
  const auth = getAuth(app);
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [url, setURL] = useState('')
  const [loc, setLoc] = useState('')
  const [transferred, setTransferred] = useState(0);

  const [tags, setTag] = useState('')
  const [category, setCat] = useState('')

  const [pickedPath, setpickedPath] =  useState<any | null>(null);
  const [imgPath, setimgPath] =  useState<any | null>(null);
  const [pathname, setPathname] =  useState<any | null>(null);
  const [imgsrc, setimgSrc] =  useState<any | null>(null);

  const [username, setUname] = useState('')

  const cat = ["Corruption", "Election", "Human Rights", "Politics", "Conflict","Gender Rights","Natural Disaster"]

  const tag = ["Activisim","Arts", "Bussiness", "Civil Society",
   "Civil War", "Corruption", "Culture","Development",
    "Diplomacy", "Dispute","Energy","Environment","Ethics",
    "Human Rights","Immigration","Justice","Media","Others",
    "Race and Racism","Reconciliation","Refugees","Religion", 
    "Security","Transparency", "Youth"];
  const [date, setDate] = useState(new Date());

  const ref = useRef(null);
  const db = getDatabase();
  const storage = getStorage(app);
  const [locationServiceEnabled, setLocationServiceEnabled] = useState(false);
  const [displayCurrentAddress, setDisplayCurrentAddress] = useState('');
  const uid = auth.currentUser?.uid
  console.log(uid);

  const [isChecked, setIsChecked] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user"
  };
  const webcamRef = useRef<Webcam>(null);
  const [pic, setPic] = useState(false);
  const navigate = useNavigate();
  const capture = React.useCallback(
   async () => {
      if (webcamRef.current) {
        const imageSrc :string = webcamRef.current.getScreenshot()!;
        setimgSrc(imageSrc);
        console.log(imageSrc);
        const base64String = imageSrc.split(',')[1]
        const filename = base64String.slice(10, 15)
        console.log(filename)

        const storageRef = ref_storage(storage, `/files/img${filename}`);
        const metadata = {
          contentType: 'image/*',
        };
       // const uploadTask = uploadBytesResumable(storageRef, pickedPath);
       try { 
        await uploadString (storageRef, base64String, "base64", {contentType: 'image/jpeg'}).then
        ((snapshot:any) => {
          setTransferred(
          Math.round(snapshot.bytesTransferred /
           snapshot.totalBytes) * 10000);
        })
         alert('Upload img successfully');
         getDownloadURL(storageRef)
         .then((url) => {
           console.log(url); 
           //setUrl(url);
           setpickedPath(url)
         })
         .catch((error) => {
          alert(error)
          });
         } catch (error) {
             alert(error)
         } 
      }
      setPic(false);
    },
    [webcamRef]
  );

  const handleChangeA = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const upload = async (selectorFiles:any) => {
    if (!selectorFiles) {
    alert("Please upload an image first!");
    }
    if (selectorFiles) {
    setimgPath(selectorFiles[0]);
    console.log(selectorFiles[0]['name']);
    setPathname(selectorFiles[0]['name'])
    console.log( URL.createObjectURL(selectorFiles[0]))
    setpickedPath( URL.createObjectURL(selectorFiles[0]))

    const storageRef = ref_storage(storage, `/files/${selectorFiles[0]['name']}`);
    const metadata = {
      contentType: '*/*',
    };
   try { 
    await uploadBytes (storageRef, selectorFiles[0], metadata).then
    ((snapshot:any) => {
      setTransferred(
      Math.round(snapshot.bytesTransferred /
       snapshot.totalBytes) * 10000);
    })
    // alert('Upload file successfully');
     getDownloadURL(storageRef)
     .then((url) => {
       console.log(url); 
       //setUrl(url);
       setpickedPath(url)
     })
     .catch((error) => {
       switch (error.code) {
         case 'storage/object-not-found':
           // File doesn't exist
           alert("File doesn't exist")
           break;
         case 'storage/unauthorized':
           // User doesn't have permission to access the object
           alert("User doesn't have permission to access the object")
           break;
         case 'storage/canceled':
           alert("User canceled the upload")
           break;  
         case 'storage/unknown':
           // Unknown error occurred, inspect the server response
           alert("Unknown error occurred, inspect the server response")
           break;
       }});
     } catch (error) {
         alert(error)
     } finally {
     };
  }}

  const RenderList=()=>{
    let tempArray = [];
      let times = tag;
   
           for(var i = 0; i < times.length; i++){
              tempArray.push(<option>{times[i]}</option>)
           }
   
           return tempArray
   }
  const RenderList1=()=>{
    let tempArray = [];
      let times = cat;
   
           for(var i = 0; i < times.length; i++){
              tempArray.push(<option>{times[i]}</option>)
           }
   
           return tempArray
   }
  
   useEffect(() => {
    onValue(dbref(db, 'users/' + uid), (snapshot) => {
      const username = (snapshot.val() && snapshot.val().name);
      setUname(username);
      console.log(username);
    })
  }, []);
  const AddPost = async () =>{
    //console.log(uri);
    try {
      const reference = dbref(db, 'posts/');
    
      push(reference, {
         userid: uid,
         username: username,
         path: pickedPath,
         title: title,
         text: text,
         url: url,
         location: displayCurrentAddress,
         category: category,
         tag: tags,
         isSensitive: isChecked,
         created_At: date.toLocaleDateString(),
         like: 0,
      });
    
     alert("Post added!!!")
     navigate('/issues', { replace: true });

    } catch (error) {
      alert(
        error
      )
    }
  }
 
    return (
      
      <div>
          <div>
       <header id="site-header" className="fixed-top">
  <div className="container">
  
 <nav className="navbar navbar-expand-lg stroke">
 <h1><Link className="navbar-brand mr-lg-5" to="/">
     <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
   </Link></h1>

 <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon fa icon-expand fa-bars" />
   <span className="navbar-toggler-icon fa icon-close fa-times " >  
   </span>
 </button>
 <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
   <ul className="navbar-nav w-100">
     <li className="nav-item active">
       <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
     </li>
     <li className="nav-item @@about__active">
       <Link className="nav-link" to="/about">About</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/contact">Contact</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/privacy-policy">Pivacy Policy</Link>
     </li>
     <li className="ml-lg-auto mr-lg-0 m-auto">
       {/*/search-right*/}
       <div className="search-right">
         <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
         {/* search popup */}
         <div id="search" className="pop-overlay">
           <div className="popup">
             <h4 className="mb-3">Search issue</h4>
             <form action="error.html" method="GET" className="search-box">
               <input type="search" placeholder="Enter Keyword" name="search" />
               <button type="submit" className="btn btn-style btn-primary">Search</button>
             </form>
           </div>
           <a className="close" href="#close">×</a>
         </div>
         {/* /search popup */}
       </div>
       {/*//search-right*/}
     </li>
     <li className="nav-item ">
     <Link className="nav-link" to="/login">Login</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link" to="/register">Register</Link>
     </li>
   </ul>
 </div>
 
</nav>
   
  </div>
</header>
 {/* bg */}
 <div className="w3l-bg py-3 ">
    <div className="container py-lg-4 py-md-3">
      <div className="welcome-left text-center py-lg-2 py-md-3" style={{marginTop:80}}>
      <h3 className="title-big"> ZUELA</h3>
    <div className="welcome-left text-center py-lg-2 py-md-3" >
      <h2 className="title-small">Zuela is a civic engagement, social networking
               platform aimed at promoting good governance.
               It provides a medium where individuals can share,
               engage, and interact together or with political
               organizations and leaders on issues they feel
               passionate about.</h2>
                    </div>
   
      </div>
    </div>
  </div>
  {/* banner image bottom shape */}
  <div className="position-relative">
    <div className="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
        </path>
      </svg>
    </div>
  </div>
  {/* //banner image bottom shape */}
</div>

<div>
<section className="w3l-contact-5 py-2" id="contact">
<div className="contacts-9 py-lg-2 py-md-2">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Create Post</h3>
        <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
        <form action="#" className="text-right">
          <div className="form-grid" style={{display: "flex", flexDirection:'column', alignItems:"center"}}>
      
      {pic==true ? <>
      <Webcam
        audio={false}
        height={500}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={500}
        videoConstraints={videoConstraints}
      />
      <div onClick={capture}  className="btn btn-primary btn-style mt-1">Capture photo</div>
      </>:<img alt="" width={"500px"} src={imgsrc}/> }
          
          
        {imgPath && (
        <div>
        <img alt="" width={"500px"} src={URL.createObjectURL(imgPath)} />
        <p> {pathname} </p>
        <br />
        </div>
           )}
       
          <div style={{display:"flex", flexDirection:'row', background:"white", padding:10}}>
          <div style={{background:"skyblue",  borderRadius:100,
            marginTop:10, marginRight:100 }}
            onClick={(e) => {
              setPic(true)
            }}
            >
            <label htmlFor="my-file1" >
            <img src="https://www.freeiconspng.com/thumbs/camera-icon/camera-icon-21.png" width="100" height="100" /> 
           </label>
           </div>
          <div style={{background:"skyblue",  borderRadius:100,
             marginTop: 10, marginRight:100, marginLeft:50}}>
           <input  type="file" name="file2" id="my-file2" accept='image/*' style={{display:"none"} }
           onChange={(e) => {
            console.log(e.target.files);
            upload(e.target.files)
          }}
           />
           <label htmlFor="my-file2" >
           <img src="https://static.thenounproject.com/png/47706-200.png" width="100" height="100" /> 
           </label>
            </div>
       
           <div style={{background:"skyblue",  borderRadius:100,
    marginTop: 10, marginRight:100}}>
  <input type="file" name="file3" id="my-file3"  accept='audio/*' 
  style={{display:"none"} }
  onChange={(e) => {
    console.log(e.target.files);
    upload(e.target.files)
  }}
  />
  <label htmlFor="my-file3" >
    <img src="https://findicons.com/files/icons/770/token_dark/256/headphones.png " width="100" height="100" /> 
  </label>
           </div>
           <div style={{background:"skyblue",  borderRadius:100,
    marginTop: 10, marginRight:50}}>
  <input type="file" name="file4" id="my-file4"
   style={{display:"none"} }
   onChange={(e) => {
    console.log(e.target.files);
    upload(e.target.files)
  }}
   />
  <label htmlFor="my-file4" >
    <img src="https://static.thenounproject.com/png/225901-200.png" width="100" height="100" /> 
  </label>
           </div>
          </div>
  


           <input onChange={(e) => setTitle(e.target.value)} type="title"  placeholder="Title*" required style={{color:"#000"}} />
            <input onChange={(e) => setText(e.target.value)} type="text" placeholder="Text"  style={{color:"#000"}}/>
            <input onChange={(e) => setURL(e.target.value)} type="url"  placeholder="URL"  style={{color:"#000"}} />
            <input onChange={(e) => setLoc(e.target.value)} type="location" placeholder="Location*" required style={{color:"#000"}}/>
            <select  onChange={(e) => setTag(e.target.value)} style={{textAlign:"left", padding:15}}>
            <option value="none" selected>Select Tag</option>
              {RenderList()}</select>
            <select  onChange={(e) => setCat(e.target.value)} style={{textAlign:"left", padding:15}}>
            <option value="none" selected>Select Category</option>
              {RenderList1()}</select>
             
        <FormControlLabel
          value="end"
          control={<Checkbox {...label} color="default"
          onChange={handleChangeA} 
          />}
          label="Does this post contain any sensitive content?"
          labelPlacement="end"
        />
              


                     
            <div style={{alignItems:"center", justifyContent:"center" }}>
            
            <div onClick={AddPost} className="btn btn-primary btn-style mt-3">Add Post</div>
</div>

          </div>

        </form>
       
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
     </div>
          )
  }
export default Posts;
