import React, { Component, useState, useEffect } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, onValue} from "firebase/database";
import { useNavigate } from 'react-router-dom';

interface Props {
}

const CreatePoll: React.FC<Props> = ({}) => {

  
  const db = getDatabase();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const [title, setTitle] =  useState<any | null>(null);
  const [uid, setUId] =  useState<any | null>(null);
  
  const [date, setDate] = useState(new Date());
  //const uid = route.params.UId
  const [uname, setUname] = useState('')
  const [country, setCountry] = useState('')

  //console.log(uid);
  const [inputs, setInputs] = useState([{ key: '', value: '', result:'' }]);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUId(uid);
        console.log(uid);
         onValue(ref(db, 'users/' + uid), (snapshot) => {
          const username = (snapshot.val() && snapshot.val().name);
          console.log(username)
          // ...
          if(username === null)
          {
            const uname = "Guest User";
            setUname("Guest User");
          }
          else
          {
          setUname(username)
          }
          console.log(uname)
        }, {
          onlyOnce: true
        });
        // ...
      } else {
        const username = "Guest User";
        setUname(username);
        // User is signed out
        // ...
      }
    
    });

   
  }, []);
  
  const addHandler = () => {
    if(inputs.length <= 10){

    const addinputs = [...inputs];
    addinputs.push({ key: '', value: '', result:'' });
    setInputs(addinputs);
    }
  };
  const deleteHandler = (key:any) => {
    const _inputs = inputs.filter((input, index) => index != key);
    setInputs(_inputs);
  };
  const inputHandler = (text:any, key:any) => {
    const _inputs = [...inputs];
    _inputs[key].value = text;
    _inputs[key].key = key;
    _inputs[key].result = "1";

    setInputs(_inputs);
  };
  function handleSelection(e:any) {
        console.log(e);
        let c=e
        setCountry(c.country)
      }

  function addPoll(){
      
        try {
            const reference = ref(db, 'polls/');
            push(reference, {
              title: title,
              country: country,
              statements: inputs,
              startedAt: date.toLocaleDateString(),
              userid: uid,
              username:uname
              
            });
            alert("Poll added successfully")
           // window.history.pushState({}, '','/poll')
            //window.history.go(0);
            navigate('/poll', { replace: true });


        } catch (error:any) {
         
          alert(error)
        }
      }
    return (

<div>
      <HeaderMain/>
<div>
<section className="w3l-contact-7 py-5" id="contact">
<div className="contacts-9 py-lg-5 py-md-4">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Create Poll {uname}</h3>
        <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
        <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="text-right">
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
          <input onChange={(e) => setTitle(e.target.value)} type="text" name="w3lName" id="title" placeholder=" Title*" required />
         
          <select onChange={(e) => setCountry(e.target.value)} style={{textAlign:"left", padding:15}}>
            <option value="none" selected>Select Country</option>
            <option value="male">Algeria</option>
            <option value="female">Egypt</option>
            <option value="other">United States</option>
            <option value="male">Pakistan</option>
            <option value="female">Protguese</option>
            <option value="other">Russia</option>
         </select> 
         

          </div>

        <h3 className="title-big">Make Statements</h3>
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
            <p> [Every statement will be answered in rating from 1-10]</p>
            {inputs.map((input, key) => (
            <div style={{flexDirection:"row"}}>
              <input
               type="text"
                placeholder={' Statement'}
                value={input.value}
                onChange={(text) => inputHandler(text.target.value, key)}
              />

           <div onClick={addHandler} className="btn btn-primary btn-style mt-3">
         <h4>+</h4>  
          </div>
              <div onClick={() => deleteHandler(key)} className="btn btn-primary btn-style mt-3">
              <h4> -</h4>
              </div>
             
            </div>
          ))}
            
          </div>
          <div onClick={addPoll} className="btn btn-primary btn-style mt-3">Create Poll</div>

        </form>
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
      </div>
    )
  }
export default CreatePoll;
