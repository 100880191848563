import React, { Component , useState, useEffect} from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'

import app from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, onValue} from "firebase/database";
import { useNavigate } from 'react-router-dom';

interface Props {
}

const CreateProject: React.FC<Props> = ({}) => {

  
  const db = getDatabase();
  const auth = getAuth(app);
  const [name, setname] = useState<any | null>(null);
  const [desc, setDesc] = useState<any | null>(null);
  const [fundReq, setFundReq] = useState<any | null>(null);
  const [date, setDate] =useState<any | null>(null);
  const [amount, setAmount] = useState<any | null>(0);
  const navigate = useNavigate();

  
  const addProject = () => {
    if (name ==='' || desc === '' || fundReq==='') {
      alert('All fields are required')
      return;
    }
  
    try {
     
        const reference = ref(db, 'projects/');
        push(reference, {
          name: name,
          description: desc,
          fundReq:fundReq,
          amount:0,
          projectStartDate: date,
        });
        alert("Project added successfully")

        navigate('/project', { replace: true });

    } catch (error:any) {
      alert(error.message)
    }
  }
    
    return (
        <div>
        <HeaderMain/>
  <div>
  <section className="w3l-contact-7 py-5" id="contact">
  <div className="contacts-9 py-lg-5 py-md-4">
  <div className="container">
    <div className="top-map">
      <div className="row map-content-9">
        <div className="col-lg-12" >
          <h3 className="title-big">Create Project</h3>
          <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
          <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="text-right">
            <div className="form-grid" >
            <input onChange={(e) => setname(e.target.value)}  type="text"  placeholder="Project Name*" required style={{color:"#000"}} />
            <input onChange={(e) => setDesc(e.target.value)}  type="text"  placeholder="Project Description" required style={{color:"#000"}}/>
            <input onChange={(e) => setFundReq(e.target.value)}  type="number"  placeholder="Funds Required*" required style={{color:"#000"}}/>
           <input type="date" onChange={(e) => setDate(e.target.value)} 
             style={{color:"#000"}}></input>         
           </div>
           <div onClick={addProject} className="btn btn-primary btn-style mt-3">Create</div>
          </form>
        </div>
       
      </div>
    </div>
  </div>
  </div>
  </section>
  
  </div>
  
        <FooterMain/>
       </div>
    )

}

export default CreateProject;