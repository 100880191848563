import React, {  useState} from 'react'
import FooterMain from './FooterMain'
import app from '../FundRaisingProjects/firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, set,  ref as ref_database, push } from 'firebase/database';
import { getStorage , uploadBytesResumable ,uploadBytes, ref as ref_storage, getDownloadURL, ref} from "firebase/storage";
import {Link, useNavigate} from 'react-router-dom'

interface Props {
}

const RegisterOrganization: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const storage = getStorage(app);
  const navigate = useNavigate();

  const [orgnamee, setOrgName] = useState<any | null>(null);
  const [orgaddres, setOrgAddress] = useState<any | null>(null);
  const [orgtype, setOrgType] = useState<any | null>(null);
  const [logo, setLogo] = useState<any | null>(null);
  const [date, setDate] = useState<any | null>(null);
  const [name, setName] = useState<any | null>(null);
  const [email, setEmail] = useState<any | null>(null);
  const [phone, setPhn] = useState<any | null>(null);
  const [password, setPwd] = useState<any | null>(null);
  const [pname, setPname] = useState<any | null>(null);
  const [pemail, setPemail] = useState<any | null>(null);
  const [statement, setStat] = useState<any | null>(null);
  const [file, setFile] = useState<any | null>(null);
  const [percent, setPercent] = useState(0);
  
  const orgReg=()=> {
    if (!file) {
      alert("Please upload an image first!");
  }
  console.log(file[0])
  const file0 = file[0]
  console.log(file0.name)

  const metadata = {
    contentType: 'image/jpeg',
  };
  const storageRef = ref_storage(storage, 'images/'+file0.name);

  // progress can be paused and resumed. It also exposes progress updates.
  // Receives the storage reference and the file to upload.
  const uploadTask = uploadBytesResumable(storageRef, file0, metadata);

  uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
      },
      (err) => alert(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              console.log(url)
               setLogo(url)
             //  alert("uploaded")
          });
      }
  );
   console.log(email,password, logo)
    try {
      createUserWithEmailAndPassword(auth,email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        const uid = user.uid
        console.log(uid)
        const reference = ref_database(db, 'users/' + uid);
        set(ref_database(db, 'users/' + uid), {
          orgname:orgnamee,
          orgaddress:orgaddres,
          pic: logo,

          dateofEstablish:date,
          orgtype: orgtype,
          name: name,
          
          email: user.email,
          password: password,
          pname: pname,
          
          pemail:pemail,
          phoneno: phone,
          statement:statement,
          
        });
        navigate('/issues', { replace: true });
        
      })
      .catch((error) => {
    
        alert(error);
        // ..
      });
    

    } catch (error:any) {
      alert(error.message)
    }
  }
 
  return (
      <div>
        <div>
       <header id="site-header" className="fixed-top">
  <div className="container">
  
 <nav className="navbar navbar-expand-lg stroke">
 <h1><Link className="navbar-brand mr-lg-5" to="/">
     <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
   </Link></h1>

 <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon fa icon-expand fa-bars" />
   <span className="navbar-toggler-icon fa icon-close fa-times " >  
   </span>
 </button>
 <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
   <ul className="navbar-nav w-100">
     <li className="nav-item active">
       <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
     </li>
     <li className="nav-item @@about__active">
       <Link className="nav-link" to="/about">About</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/contact">Contact</Link>
     </li>
     <li className="ml-lg-auto mr-lg-0 m-auto">
       {/*/search-right*/}
       <div className="search-right">
         <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
         {/* search popup */}
         <div id="search" className="pop-overlay">
           <div className="popup">
             <h4 className="mb-3">Search issue</h4>
             <form action="error.html" method="GET" className="search-box">
               <input type="search" placeholder="Enter Keyword" name="search" />
               <button type="submit" className="btn btn-style btn-primary">Search</button>
             </form>
           </div>
           <a className="close" href="#close">×</a>
         </div>
         {/* /search popup */}
       </div>
       {/*//search-right*/}
     </li>
     <li className="nav-item ">
     <Link className="nav-link" to="/login">Login</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link" to="/register">Register</Link>
     </li>
   </ul>
 </div>
 
</nav>
   
  </div>
</header>
 {/* bg */}
  {/* bg */}
  <div className="w3l-bg py-3 ">
    <div className="container py-lg-4 py-md-3">
      <div className="welcome-left text-center py-lg-2 py-md-3" style={{marginTop:80}}>
      <h3 className="title-big"> ZUELA</h3>
    <div className="welcome-left text-center py-lg-2 py-md-3" >
      <h2 className="title-small">Zuela is a civic engagement, social networking
               platform aimed at promoting good governance.
               It provides a medium where individuals can share,
               engage, and interact together or with political
               organizations and leaders on issues they feel
               passionate about.</h2>
                    </div>
   
      </div>
    </div>
  </div>
  {/* banner image bottom shape */}
  <div className="position-relative">
    <div className="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
        </path>
      </svg>
    </div>
  </div>
  {/* //banner image bottom shape */}
</div>

<div>
<section className="w3l-contact-5 py-2" id="contact">
<div className="contacts-9 py-lg-2 py-md-2">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Organization Register</h3>
        <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
        <h3 className="title-big">Organization Info</h3>
        <form  className="text-right">
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
          <input onChange={(e) => setOrgName(e.target.value)}  type="text" placeholder=" Name of Organization*" required />
          <input onChange={(e) => setOrgAddress(e.target.value)}  type="text" placeholder="Address*" required style={{color:"#000"}}/>
         
          <select onChange={(e) => setOrgType(e.target.value)} style={{textAlign:"left", padding:15}}>
            <option defaultValue="none" >Organization Type</option>
            <option value="1">Government</option>
            <option value="2">NGO</option>
            <option value="3">Company</option>
            <option value="4">Civil Society Group</option>
            <option value="5">Academic</option>
            <option value="6">Institution</option>
         </select> 
         <div style={{display: "flex", flexDirection:'row', color:"black"}}>
            <label htmlFor="myfile">Logo:</label>
                    <input onChange={(e) => {
                     console.log(e.target.files);
                     setFile(e.target.files)
}                      
                      }  accept="image/*" type="file" />
          </div>
          <div style={{display: "flex", flexDirection:'row', color:"black"}}>
            <label htmlFor="myfile">Date:</label>
                    <input onChange={(e) => setDate(e.target.value)}  type="date" 
           style={{color:"#000"}}/>
          </div>
         

          </div>

        <h3 className="title-big">Account Info</h3>
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
            <input onChange={(e) => setName(e.target.value)}  type="text" placeholder=" Name*" required />
            <input onChange={(e) => setEmail(e.target.value)}  type="email" placeholder="Email*" required style={{color:"#000"}} />
            <input onChange={(e) => setPwd(e.target.value)}  type="password" placeholder="Set Password*" required style={{color:"#000"}}/>
          </div>

        <h3 className="title-big">Contact Person Info</h3>
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
           
                  <input  onChange={(e) => setPname(e.target.value)}  type="text" placeholder="Person Name*" required />
                  <input onChange={(e) => setPemail(e.target.value)}  type="email"  placeholder="Email Address*" required />
                  <input onChange={(e) => setPhn(e.target.value)}  type="text"  placeholder="Phone number*" required />
                  <textarea  onChange={(e) => setStat(e.target.value)}  placeholder="Company Mision Statement" aria-multiline />
            <div onClick={orgReg} className="btn btn-primary btn-style mt-3">Register</div>

          </div>

        </form>
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
     </div>    )
  
}
export default  RegisterOrganization;