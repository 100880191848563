import React, { Component, useEffect, useState } from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link, useLocation } from 'react-router-dom'

import app from '../FundRaisingProjects/firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";

interface Props {
}

const Polls: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db = getDatabase();
  const [polls, setPoll] =useState<any | null>(null);;


  useEffect(() => {

      let isMounted = true; 
     
      onValue(ref(db, 'polls/'), (snapshot) => {
       let li: {key:any, title: string, username:string,startedAt:any }[]=[];

      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        title: child.val().title,
        username:child.val().username,
        startedAt: child.val().startedAt,
      })
      
      setPoll(li)
      
    })});

    return() => {isMounted = false};
  }, []);
 
 
  return (
      <div>
      <div>
            <HeaderMain/>
      <div>
      <section className="w3l-contact-7 py-5" id="contact">
      <div className="contacts-9 py-lg-5 py-md-4">
      <div className="container">
        <div className="top-map">
          <div className="row map-content-9">
            <div className="col-lg-12" >
              <h3 className="title-big">Poll Dashboard</h3>
              <p className="mb-4 mt-lg-0 mt-2"  style={{color:"red"}}>Required fields are marked *</p>
              <div className="card">
          <div className="card-header">
            <h3 className="card-title">Polls</h3>
            <div className="card-tools">
              <div className="input-group input-group-sm" style={{width: 150}}>
              <Link to="/createpoll" >
                <span className="btn bg-success">Create Poll</span>
              </Link>
              </div>
            </div>
          </div>
          <div className="card-body table-responsive p-0">
  <table className="table table-hover text-nowrap">
    <thead>
      <tr>
        <th>Poll Title</th>
        <th>Started By</th>
        <th>Started At</th>
        <th>Take Poll</th>
        <th>Results</th>
  
      </tr>
    </thead>
    <tbody>
    {polls && polls.map((item:any) => (

      <tr>
        <td>{item.title}</td>
        <td>{item.username}</td>
        <td>{item.startedAt}</td>
        <td>
        <Link to="/takepoll"  state={item.key}>
        <span className="badge bg-success">Take</span>
        </Link></td>
        <td>
        <Link to="/viewpoll" state={item.key}>
          <span className="badge bg-info">View</span>
          </Link></td>
      </tr>
    ))}
    </tbody>
  </table>
</div>

        </div>
            </div>
           
          </div>
        </div>
      </div>
      </div>
      </section>
      
      </div>
      
            <FooterMain/>
            </div></div>
    )
  
}
export default Polls;
