import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
<div>
  <footer className="main-footer">
  <strong>Copyright © 2022 <a href="https://zuela.org/">zuela.org</a> </strong>
   | All rights reserved.
    
  </footer>
</div>

    )
  }
}
