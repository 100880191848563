import React, {  useMemo, useState, useCallback ,  useEffect} from 'react'
import FooterMain from '../main-components/FooterMain'
import HeaderMain from '../main-components/HeaderMain'
import { Link } from 'react-router-dom'
import app from '../FundRaisingProjects/firebase';
import "./issues.css"
import "./css/color.css"
import "./css/responsive.css"
import { getAuth , onAuthStateChanged, signOut} from "firebase/auth";
import { getDatabase,push, ref, onValue, update} from "firebase/database";
interface Props {
}

const Issues: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db= getDatabase(app);

  const [post, setPost] = useState([]);
  const [user, setUser] = useState<any | null>(null);
  // filter post with category
  const [cat, setCategory] = useState('NONE')
  const[like, setLike]=  useState<any | null>(null);
  const[islike, setisLike]= useState(true)

  const [messages, setMessages] = useState([]);
 
  const [email, setEmail] = useState();
  const [uname, setUname] = useState();
  const [token, setToken] = useState();
  const [pid, setPid] = useState();
  const [count, setCount] = useState(0);
  const [txtmsg, setText] = useState('');
  const uid = auth?.currentUser?.uid
  const [uidd, setUId] = useState('')
  const [pic,setPic] = useState<any | null>(null);

  
 useEffect(() => {
      let isMounted = true;
      onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            setUser(user);
            setUId(uid);
          console.log(uid);
           onValue(ref(db, 'users/' + uid), (snapshot) => {
            const username = (snapshot.val() && snapshot.val().name);
            const upic = (snapshot.val() && snapshot.val().pic);
 
            // ...
            setUname(username);
            setPic(upic);
          }, {
            onlyOnce: true
          });
       
        } 
      });
    
      return() => {isMounted = false};
    }, []);
  
  const filteredList = useMemo(
    () => {
      if (cat === 'NONE' ) return post
      return post.filter((item:any) => item.category === cat)
    },
    [cat, post]
  )
  const onClick = (c:any) => () => {
      setCategory(c)
  }
  useMemo(() => {
      onValue(ref(db, 'posts/'), (snapshot) => {
      let li:any = []
      snapshot.forEach((child)=>{
      
       li.push({
        key:  child.key,
        uid: child.val().userid,
        name: child.val().username,
        time: child.val().created_At,
        text: child.val().text,
        path: child.val().path,
        title: child.val().title,
        url: child.val().url,
        location: child.val().location,
        category: child.val().category,
        tag: child.val().tag,
        isSensitive: child.val().isSensitive,
        like: child.val().like,
        ext : child.val().path.split("%").pop().split("?", 1).pop().split(".").pop(),
      })
      setPost(li);
      
    })});
  }, []);
 
  const onComment =(pid: any)=>{

    onValue(ref(db, 'comments/'), (snapshot) => {
      let msg :any= []
      snapshot.forEach((child)=>{
       msg.push({
        _id: child.key,
        createdAt: child.val().createdAt,
        text: child.val().text,
        user: child.val().username,
        pid: child.val().postId,
        
      })
     msg = msg.sort((a:any,b:any)=> a.createdAt - b.createdAt);
     const msg1= msg.filter((item:any) => item.pid == pid );
     setMessages(msg1)
     const totalProps = msg1.length;
      //console.log(totalProps)
     setCount(totalProps);
    // return() => {isMounted = false};

    })});
  }
  const postOnFacebook = (uri:any, txt:any) => {
    let facebookParameters = [];
      facebookParameters.push('u=' + encodeURI(uri));
      facebookParameters.push('quote=' + txt);
    const url =
      'https://www.facebook.com/sharer/sharer.php?'
       + facebookParameters.join('&');
 
   try{
    window.location.href = url;
   }
      catch(error)  {
        alert('Something went wrong');
      };
  };  
  const onSend =(pid:any) => {
    console.log("msgs")

    const temp:any = new Date().toLocaleString() 
    const created_At = Date.parse(temp.createdAt); //<--- add this line

    try {
    console.log(
      uid ,
      Date().toLocaleString(), txtmsg,pid,uname
    ) 
     setText('')
      const reference = ref(db, 'comments/');
      push(reference, 
        { _id:uid ,
          createdAt:Date().toLocaleString(),
          text: txtmsg,
          postId:pid,
          username:uname}  
      );
      const message = {
        to: token,
        sound: 'default',
        title:  `${uname}`,
        body:   `${txtmsg}`,
        data: { someData: 'goes here' },
      };
    
       fetch('https://exp.host/--/api/v2/push/send', {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Accept-encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      });
      const notRef = ref(db, 'notification/');
      push(notRef, {
         senderid: auth?.currentUser?.uid,
         title:  `${uname}`,
         body:`${uname} comment ${txtmsg}` ,
         created_At: Date().toLocaleString(),
      }); 
    
  } catch (error) {
    
    alert(error);
  }
  }
return (
  <div>

    <HeaderMain/>
<section>
<div className="gap gray-bg">
<div className="container-fluid">
  <div className="row">
    <div className="col-lg-12">
      <div className="row" id="page-contents">
        <div className="col-lg-3">
          <aside className="sidebar static">
          <div className="advertisment-box">
            <h4>advertisment</h4>
            <figure>
              <a href="#" title="Advertisment"><img src="dist/img/ad-widget.jpg"   /></a>
            </figure>
          </div>
            <div className="widget">
              <h4 className="widget-title">Dashboard</h4>
              <ul className="naves">
                <li>
                  <i className="fa fa-clipboard" />
                  <Link to="/issues" >News feed</Link>
                </li>
                
                <li>
                  <i className="fa fa-envelope" />
                  <Link  to="/messages">Messages</Link>
                </li>
                <li>
                  <i className="fa fa-bell" />
                  <Link to="/notifications">Notifications</Link>
                </li>
                <li>
                  <i className="fa fa-file" />
                  <Link to="/project" > Projects</Link>
                </li>
                <li>
                  <i className="fa fa-bar-chart-o" />
                  <Link to="/poll" >Polls</Link>
                </li>
                <li onClick={()=>signOut(auth)}>
                  <i className="fa fa-power-off" />
                  <Link className="nav-link" to="/"  >Logout</Link>
                </li>
              </ul>
            </div>{/* Shortcuts */}
         
          
        
          
          </aside>
        </div>{/* sidebar */}
        <div className="col-lg-6">
          { user?(
            <>
              <div className="central-meta">
            
            <div className="new-postbox">
              
              <div className="newpst-input" >
               
                <div className="comet-avatar" style={{flexDirection:'column',float:"left", alignItems:'center'}}>
                <img src= {pic} width={50} height={50}  alt = ""/>
                </div>
                <h5>Do you want to post an issues?</h5> 

                    <Link to="/addpost" className="btn btn-primary ">Add Post</Link>


              </div>
            </div>
          </div>
            </>):null}
          {
          filteredList.map((item:any) => (
            <div className="loadMore">
            <div className="central-meta item">
              <div className="user-post">
                <div className="friend-info">

                  <div className="friend-name">
                    <ins>{item.title}</ins>
                  <p>  

                    </p>  

                    <span>Posted By: {item.name} on {item.time}</span>
                    <p>
                        {item.text}
                      </p>
                  </div>
                  <div className="post-meta">
                  {item.ext == 'jpg'||item.ext == 'png' ||item.ext == 'jpeg' ?(
                  <img src= {item.path} width={250} height={200}  alt = ""/>
                  ):
                  item.ext == 'mp4'?(
                    <video controls width="100%" height="90%">   
                    <source src={item.path} type="video/mp4"/>
                  </video>
                  ):
                  item.ext == 'mp3'?(
                    <audio controls >   
                    <source src={item.path} type="audio/mpeg"/>
                  </audio>):
                  item.ext == 'pdf'||item.ext == 'doc'|| item.ext == 'docx' || item.ext == 'xls' ?(
                    <div style={{padding:50,width:"100%",height:"90%", backgroundColor:"white", borderRadius:5, borderColor:"black",borderStyle: "solid"}}>           
                    <p  style={{fontSize:30, justifyContent:"center", alignSelf:"center", color:"blue", textDecorationLine:"underline"}}><a href={item.path}>Open a file here</a>.</p>
                    </div>
                    ):
                    <img src= {item.path} width="80%" height= {200}  alt = ""/>



                  }
                {user? (<>
                  <div className="we-video-info">

<ul>
  <li className="social-media"
  onClick={() => {
let likee:any = item.like
setLike(islike? likee+1:likee-1)
setisLike(!islike)
let postData = {
userid: item.uid,
username: item.name,
path: item.path,
title: item.title,
text: item.text,
url: item.url,
location: item.location,
category: item.category,
tag: item.tag,
isSensitive: item.isSensitive,
created_At: new Date().toLocaleDateString(),
like: likee+1

};

console.log(postData)
console.log(likee)
//console.log(count)
update(ref(db,'posts/'+item.key ), postData)
}}>
    <div className="menu">
      <div className="btn trigger" >
      <span className="views" data-toggle="tooltip" title="views">
      <i className="fa fa-heart"
      />
      <p>Like</p>
      <ins>{item.like}</ins>
    </span>
      </div>                     
    </div>
  
  </li>
  <li className="social-media" onClick={()=>onComment(item.key)}>

  <div className="menu">
    <div className="btn trigger" >
    <span> 
    <i className="fa fa-comments-o" />
    <p>Comment</p>
    { messages && messages.map((items:any) => (
      <>
      {
        item.key === items.pid &&
        <ins>{count}</ins>
        || <ins>0</ins>
  
        }
      </>)
      )}
    </span>
      </div>                     
    </div>
  </li>
  <li className="social-media">
    <div className="menu">
      <div className="btn trigger" onClick={()=>postOnFacebook(item.path, item.text)} >
        <i className="fa fa-share-alt" />
        <p>Share</p>
        </div>                     
    </div>
  </li>  
  <li className="social-media">
    <div className="menu">
      <div className="btn trigger" >
        <i className="fa fa-commenting-o" />
        <Link  to="/messages" state={item.name}>Messages</Link>

      </div>                     
    </div>
  </li>


</ul>
</div>
                </>):null}
                  </div>
                </div>
                {user? (
                <>
                  <div className="coment-area" >
                
                <div>
                  <ul className="we-comet" >
                  { messages && messages.map((items:any) => (
                    
                    <li>
                      {
                        item.key === items.pid &&
                        <>
                         <div className="comet-avatar" >
                         <div style={{
        width: 60,
        height: 60,
        justifyContent: "center",
        borderRadius: 60 / 2,
        backgroundColor: 'skyblue',
      }}>
        <p style={{
          padding:10,
          paddingTop:15,
          fontWeight: 'bold',
          color: 'white',
          fontSize: 15,
        }}>{items.user}</p>
      </div>
                      </div>
                      <div className="we-comment">
                        <div className="coment-head">
                          <span>{items.created_At} </span>
                        </div>
                        <p>{items.text}
                          <i className="em em-smiley" />
                        </p>
                      </div>
                        </>
                     
                      }
                     
                    </li>
                  ))}
                    <li className="post-comment">
                      
                      <div className="post-comt-box" >
                        <div style={{flex: 1,flexDirection: 'row',}}></div>
                        <textarea placeholder="Post your comment" 
                        defaultValue={""}  
                        onClick={()=>onComment(item.key)}
                        onChange={(e) => {
                          const currentText = e.target.value;
                          if(currentText.length && currentText[currentText.length - 1] === " "){
                            setText("");
                          } else {
                            setText(e.target.value);
                          }
                        }}
                        aria-multiline ></textarea>
                  </div>
                  <div className="comet-avatar">
                  <button onClick={()=>onSend(item.key)} className="btn btn-primary "><i className="fa fa-paper-plane" /></button>                       
                      </div>
                    </li>
                  </ul>
                  </div>
            
                </div>
                </>):null}
               
              </div>
            </div>    
          </div>

          ))}


        </div>{/* centerl meta */}
        <div className="col-lg-3">
          <aside className="sidebar static">
           
         
            <div className="widget friend-list stick-widget">
              <h4 className="widget-title">Categories</h4>
              <div id="searchDir" />
              <ul id="people-list" className="friendz-list">
              <li>
                  <figure>
                    <img src="dist/img/1.png"  />
                  </figure>
                  <div className="friendz-meta" onClick={onClick('Corruption')}>
                    <a href="#">Corruption</a>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src="dist/img/2.png"  />
                  </figure>
                  <div className="friendz-meta" onClick={onClick('Election')} >
                    <a href="#">Election</a>
                  </div>
                </li>

                <li>
                  <figure>
                    <img src="dist/img/3.png"  />
                  </figure>
                  <div className="friendz-meta"   onClick={onClick('Human Rights')}>
                    <a href="#">Human Rights</a>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src="dist/img/4.png"  />
                  </figure>
                  <div className="friendz-meta"  onClick={onClick('Politics')}>
                    <a href="#">Politics</a>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src="dist/img/5.png"  />
                  </figure>
                  <div className="friendz-meta"  onClick={onClick('Conflict')}>
                    <a href="#">Conflict</a>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src="dist/img/6.png"  />
                  </figure>
                  <div className="friendz-meta"  onClick={onClick('Gender Rights')}>
                    <a href="#">Gender Rights</a>
                  </div>
                </li>
                <li>
                  <figure>
                    <img src="dist/img/7.png"  />
                  </figure>
                  <div className="friendz-meta"  onClick={onClick('Natural Disasters')}>
                    <a href="#">Natural Disasters</a>
                  </div>
                </li>
              </ul>
            
            </div>{/* friends list sidebar */}

            <div className="widget friend-list stick-widget">
              <h4 className="widget-title">Search By</h4>
              <div id="searchDir" />
              <ul id="people-list" className="friendz-list">
              <li>
                
                  <div className="friendz-meta">
                  <Link to="/issues" >Date</Link>

                  </div>
                </li>
                <li>
                 
                  <div className="friendz-meta">
                  <Link to="/issues" >Country</Link>
                  </div>
                </li>

                <li>
                  
                  <div className="friendz-meta">
                  <Link to="/issues" >Filter By</Link>
                  </div>
                </li>
                <li>
                 
                  <div className="friendz-meta">
                  <Link to="/issues" >Media</Link>
                  </div>
                </li>
                <li>
                 
                  <div className="friendz-meta">
                  <Link to="/issues" >Response</Link>
                  </div>
                </li>
              
              </ul>
            </div>{/* friends list sidebar */}
              </aside>
        </div>{/* sidebar */}
      </div>	
    </div>
  </div>
</div>
</div>	
</section>


    <FooterMain/>
  </div>
)
  }

  export default Issues;

