import React, { Component , useEffect, useState} from 'react'
import Header from '../Main-admin/Header';
import Menu from '../Main-admin/Menu';
import Footer from '../Main-admin/Footer';
import app from '../Main/FundRaisingProjects/firebase';
import { getAuth } from "firebase/auth";
import { getDatabase,update, ref, onValue} from "firebase/database";

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';



interface Props {
}
const ApprovalRequest: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const db= getDatabase(app);
  const [lstuser, setUser] =  useState<any | null>(null);
  const [open, setOpen] = React.useState(false);
const [uname, setUname] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [dob, setDob] = useState('')
const [pic, setPic] = useState('')
const [gender, setGen] = useState('')
const [status, setStatus] = useState('Disapproved')
const [uid, setUid] = useState('')

  const handleClickOpen = (id:any) => {
    setOpen(true);
    setUid(id)
    onValue(ref(db, 'users/' + id), (snapshot) => {
      const username = (snapshot.val() && snapshot.val().name);
      const uemail = (snapshot.val() && snapshot.val().email);
      const uphone = (snapshot.val() && snapshot.val().phoneno);
      const udob = (snapshot.val() && snapshot.val().dateofBirth);
      const upic = (snapshot.val() && snapshot.val().pic);
      const ugen = (snapshot.val() && snapshot.val().gender);
      const ustatus = (snapshot.val() && snapshot.val().status);

      setPic(upic)
      console.log(username)
      setUname(username)
      setEmail(uemail)
      setPhone(uphone)
      setDob(udob)
      setGen(ugen)
      setStatus(ustatus)

    }, {
      onlyOnce: true
    });
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  function updateOrg(uid:any, s:any) {
 
  
    console.log(s);
    if (s === "Disapproved")
    {
      s = "Approved"
    }
    else{
      
      s = "Disapproved"
    }
    console.log(s)
    update(ref(db, 'users/' + uid), {
      name: uname,
      email: email,
      phoneno: phone,
      pic: pic,
      status: s,
    })
    .then(() => {
     // alert("updated!!!")
    })
    .catch((error) => {
      // The write failed...
    });
  
    
  };
  useEffect(() => {
  
    let isMounted = true; 
    onValue(ref(db, 'users/'), (snapshot) => {
      let li: {key:any, orgname: string, orgtype:string,orgaddress:string, phoneno:number, pname:string, status:any }[]=[];
      snapshot.forEach((child)=>{
       li.push({
        key: child.key,
        orgname:child.val().orgname,
        orgtype : child.val().orgtype,
        orgaddress: child.val().orgaddress,
        phoneno: child.val().phoneno,
        pname:  child.val().pname,
        status: child.val().status

        })
      li = li.filter((item)=> item.orgname !== undefined )
      console.log(li)
      setUser(li);

    })});

  return() => {isMounted = false};
}, []);
    return (
      <div>
      <Header/>
      <Menu/>
      <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Approval Request</h1>
            </div>
            
          </div>
        </div>
      </div>
    <section className="content">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Approval Request on Zuela</h3>
            <div className="card-tools">
              
            </div>
          </div>
          <div className="card-body table-responsive p-0">
  <table className="table table-hover text-nowrap">
    <thead>
      <tr>
      <th>ID</th>
        <th>Organization</th>
        <th>Type</th>
        <th>Address</th>
        <th>Contact No</th>
        <th>Person</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
    {lstuser && lstuser.map((item:any) => (

      <tr key={item.key}>
        <td>{item.key} </td>
        <td>{item.orgname}	</td>
        <td>{item.orgtype}</td>
        <td>{item.orgaddress}	</td>
        <td>{item.phoneno}</td>
        <td>{item.pname}	</td>
        {item.status =="Disapproved"?<>
        <td><span className="badge bg-danger">{item.status}</span></td>
        </>:
        <td><span className="badge bg-success">{item.status}</span></td>}
        <td><span className="badge bg-info" onClick={()=>handleClickOpen(item.key)}>View</span></td>

      </tr>
    ))}
   </tbody>
  </table>
</div>
<Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {uname}
    </DialogTitle>
        <DialogContent>
          <DialogContentText>
    <div>
  <img src={pic} alt={uname} style={{width: '100%'}} />
  <div> &nbsp; </div>
   <h6><strong>Name :</strong> {uname}</h6> 
   <div> &nbsp; </div>
   <h6><strong>Email :</strong> {email}</h6> 
   <div> &nbsp; </div>
   <h6><strong>Phone no :</strong> {phone}</h6> 

  <p></p>
</div>
        
</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>updateOrg(uid, status) } color="secondary" >{status=== "Disapproved" ?"Approved":"Disapproved"}</Button>
          <Button onClick={()=>handleClose()} color="primary" autoFocus>Close</Button>
        </DialogActions>
</Dialog>
        </div></div></div></div></section>

      </div> 
      <Footer/>
      </div>   )
  
}
export default ApprovalRequest;