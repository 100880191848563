import React, { Component, useState } from 'react'
import HeaderMain  from './HeaderMain'
import FooterMain from './FooterMain'
import { ActionCodeURL, getAuth, sendPasswordResetEmail } from "firebase/auth";
import app from '../FundRaisingProjects/firebase';
interface Props {
}
const ForgotPassword: React.FC<Props> = ({}) => {
  const auth = getAuth(app);
  const [email, setEmail] = useState<any | null>(null);

  function reset() {
    try {
      sendPasswordResetEmail(auth, email)
      .then(() => {
       alert("Password reset link sent!");
    })
      .catch((error:any) => {
       const errorCode = error.code;
       const errorMessage = error.message;   
       alert("Please enter your email");

    });}
    catch (error) { 
      alert(error);
    }
  };
 
    return (
      <div>
      <HeaderMain/>
<div>
<section className="w3l-contact-7 py-5" id="contact">
<div className="contacts-9 py-lg-5 py-md-4">
<div className="container">
  <div className="top-map">
    <div className="row map-content-9">
      <div className="col-lg-12" >
        <h3 className="title-big">Forgot Password</h3>
        <form  method="post" className="text-right">
          <div className="form-grid" style={{display: "flex", flexDirection:'column'}}>
            <input  onChange={(e) => setEmail(e.target.value)} type="email" name="Sender" id="w3lSender" placeholder="Enter your Email" 
            required style={{color:"#000"}} />
        
            <div onClick={reset} className="btn btn-primary btn-style mt-3">Submit</div>
   

          </div>

        </form>
      </div>
     
    </div>
  </div>
</div>
</div>
</section>

</div>

      <FooterMain/>
     </div>    )

}
export default  ForgotPassword ;